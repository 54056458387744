import styled from "styled-components/native";
import { StyleSheet } from "react-native";

import { spacers } from "~/styles/theme";

export const Overlay = styled.Pressable`
  background-color: ${({ theme }) => theme.colors.black};
  ${StyleSheet.absoluteFill};
  opacity: 0.3;
`;

export const NavigationBar = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const NavButton = styled.Pressable<{ isLast?: boolean }>`
  padding-left: ${spacers.ss4};
  padding-right: ${({ isLast }) => (isLast ? spacers.ss6 : spacers.ss4)};
  align-items: center;
  justify-content: center;
`;
