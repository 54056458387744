import * as React from "react";
import axios from "axios";
import * as FileSystem from "expo-file-system";
import {
  openBrowserAsync,
  WebBrowserPresentationStyle,
} from "expo-web-browser";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { useAlerts } from "~/state/alerts";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { getPaymentIntentsAction } from "~/state/payments/actions";
import { getTransactionYears } from "~/state/payments/selectors";
import { genericMessages } from "~/constants/intl";
import { colors } from "~/styles/theme";
import { Loader } from "~/components/loader";
import { getApiHeaders } from "~/state/payments/utils";
import { WEB_APP_URL } from "~/constants";
import { isWeb } from "~/utils/platform";

import { Container, Title } from "../../styles";
import { AccountHeader } from "../../../header";
import { messages as generalMessages } from "../../intl";
import { Screens } from "../../../../types";
import {
  Content,
  List,
  Row,
  Text,
  Subtitle,
  ButtonBox,
  ButtonIcon,
} from "./styles";

interface Props {
  onBackPress: () => void;
  setScreen: (screen: Screens) => void;
}
interface ListProps {
  currentYears: Array<number | null>;
  setCurrentYears: (arg0: Array<number | null>) => void;
  year: number;
}

const targetDirectory = FileSystem.documentDirectory + "bep/files/tax-summary/";

const ListItem = ({ currentYears, setCurrentYears, year }: ListProps) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const [fileUri, setFileUri] = React.useState("");

  const env = useAppSelector(getEnvironment);

  const onOpenItem = React.useCallback(async (uri?: string) => {
    if (!uri) return;

    await openBrowserAsync(uri, {
      presentationStyle: WebBrowserPresentationStyle.PAGE_SHEET,
    });
  }, []);

  const onDownload = React.useCallback(async () => {
    setIsDownloading(true);
    try {
      const headers = await getApiHeaders();
      const response = await axios.get(
        `${WEB_APP_URL}/api/payments/summary-${env}?year=${year}`,
        headers
      );
      const fileUrl = response.data?.url;

      if (!fileUrl) {
        return;
      }

      if (isWeb) {
        window.open(fileUrl, "_blank");

        return;
      }

      const zipFileUri = `${targetDirectory}${year}.pdf`;

      const downloadResumable = FileSystem.createDownloadResumable(
        fileUrl,
        zipFileUri
      );

      const { uri } =
        (await downloadResumable.downloadAsync()) as FileSystem.FileSystemDownloadResult;

      if (!uri) {
        return;
      }

      setCurrentYears([...currentYears, year]);
      setFileUri(fileUrl);
      onOpenItem(fileUrl);
      setIsDownloading(false);
    } catch (e) {
      setIsDownloading(false);
    }
  }, [currentYears, setCurrentYears, year, onOpenItem]);

  const onPress = React.useCallback(
    (item: number) => {
      if (isDownloading) {
        return;
      }
      if (currentYears.includes(item)) {
        onOpenItem(fileUri);
        return;
      }
      onDownload();
    },
    [currentYears, onDownload, onOpenItem, isDownloading, fileUri]
  );

  return (
    <Row>
      <Text>{`${year}`}</Text>
      {isDownloading ? (
        <Loader size={20} />
      ) : (
        <ButtonBox onPress={() => onPress(year)}>
          <ButtonIcon isDownloaded={currentYears.includes(year)} />
        </ButtonBox>
      )}
    </Row>
  );
};

export const TaxReceipts = React.memo<Props>(({ onBackPress }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentYears, setCurrentYears] = React.useState<Array<number | null>>(
    []
  );

  const userId = useAppSelector(getUserId);
  const data = useAppSelector(getTransactionYears);

  const dispatch = useAppDispatch();
  const { pushAlert } = useAlerts();

  React.useEffect(() => {
    if (!userId || data.length) {
      setIsLoading(false);
      return;
    }

    dispatch(
      getPaymentIntentsAction({
        onSuccess: () => setIsLoading(false),
        onError: () => {
          setIsLoading(false);
          pushAlert({
            message: genericMessages.error,
            color: colors.red500,
          });
        },
      })
    );
  }, [data]);

  React.useEffect(() => {
    const fetchPdfs = async () => {
      try {
        const directoryExists = await FileSystem.getInfoAsync(targetDirectory);

        if (!directoryExists.exists) {
          await FileSystem.makeDirectoryAsync(targetDirectory, {
            intermediates: true,
          });
          return;
        }

        const files = await FileSystem.readDirectoryAsync(targetDirectory);

        const currentFiles = files
          .filter((file) => file.endsWith(".pdf"))
          .map((file) => {
            const match = file.match(/^(\d{4})\.pdf$/);
            return match ? parseInt(match[1], 10) : null;
          })
          .filter(Boolean);

        setCurrentYears(currentFiles);
      } catch (error) {
        console.error("Error reading files:", error);
      }
    };

    fetchPdfs();
  }, []);

  const renderItem = ({ item }: any) => {
    return (
      <ListItem
        currentYears={currentYears}
        setCurrentYears={setCurrentYears}
        year={item}
      />
    );
  };

  return (
    <Container>
      <AccountHeader onBackPress={onBackPress} />

      <Title>{generalMessages.taxReceipts}</Title>

      <Subtitle>{generalMessages.taxReceiptDescription}</Subtitle>

      <Content>
        {isLoading ? (
          <Loader size={20} />
        ) : (
          <List data={data} renderItem={renderItem} />
        )}
      </Content>
    </Container>
  );
});
