export const messages = {
  profile: {
    id: "settings.profile",
    defaultMessage: "Profile",
  },
  appSettings: {
    id: "settings.app-settings",
    defaultMessage: "App Settings",
  },
  reminders: {
    id: "settings.reminders",
    defaultMessage: "Learning Reminders",
  },
  about: {
    id: "settings.about",
    defaultMessage: "About",
  },
  helpAndFeedback: {
    id: "settings.help-and-feedback",
    defaultMessage: "Help & Support",
  },
  give: {
    id: "settings.give",
    defaultMessage: "My Giving",
  },
  appFeedback: {
    id: "settings.app-feedback",
    defaultMessage: "App Feedback",
  },
  shareYourStory: {
    id: "settings.share-your-story",
    defaultMessage: "Share Your Story",
  },
  downloads: {
    id: "settings.downloads",
    defaultMessage: "Downloads",
  },
  devTools: {
    id: "settings.dev-tools",
    defaultMessage: "Developer tools",
  },
  announcements: {
    id: "settings.announcements",
    defaultMessage: "Announcements",
  },
  inAppMessages: {
    id: "settings.in-app-messages",
    defaultMessage: "In-App Messages",
  },
  logout: {
    id: "settings.logout",
    defaultMessage: "Sign Out",
  },
};
