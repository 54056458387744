import { createAction } from "@reduxjs/toolkit";
import type { AddAppFeedbackAction, SendStoryPayload } from "./types";

export const addAppFeedback =
  createAction<AddAppFeedbackAction["payload"]>("addAppFeedback");

export const openAskForFeedback = createAction("openAppFeedback");

export const dismissAskForFeedback = createAction("dismissAskForFeedback");

export const submitUserStory =
  createAction<SendStoryPayload>("submitUserStory");

export const updateUploadProgress = createAction<{ progress: number }>(
  "updateUploadProgress"
);
