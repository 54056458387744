export const messages = {
  greetingsMorningTitle: {
    id: "greetings.morning.title",
    defaultMessage: "Good Morning, {{name}}",
  },
  greetingsAfternoonTitle: {
    id: "greetings.afternoon.title",
    defaultMessage: "Good Afternoon, {{name}}",
  },
  greetingsEveningTitle: {
    id: "greetings.evening.title",
    defaultMessage: "Good Evening, {{name}}",
  },
};
