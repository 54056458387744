import styled from "styled-components/native";

import { Text6 } from "~/components/text";
import { spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const GreetingsContainer = styled.View<{ topMargin: number }>`
  flex-direction: row;
  margin-top: ${({ topMargin = 0 }) => topMargin}px;
  align-items: flex-start;
  margin-bottom: ${isWeb ? spacers.ss5 : spacers.ss8};
`;

export const TitleBox = styled.View`
  flex: 1;
  margin-left: ${spacers.ss6};
`;

export const GreetingsTitle = styled(Text6)`
  font-family: InterBold;
  color: ${({ theme }) => theme.colors.black};
`;
