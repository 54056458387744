import { FlamelinkLocales } from "./types";

export const SUPPORTED_LOCALES = Object.values(FlamelinkLocales).map(
  (locale) => locale.split("-")[0]
);

export const FLAMELINK_DATA_KEY = "flamelink.data";
export const FLAMELINK_IMAGES_KEY = "flamelink.images";

export const FILTERS_CACHE_TIME_MS = 24 * 60 * 60 * 1000; // 24 hours
export const FEATURED_CACHE_TIME_MS = 24 * 60 * 60 * 1000; // 24 hours
export const HOME_CACHE_TIME_MS = 24 * 60 * 60 * 1000; // 24 hours
