import styled from "styled-components/native";
import { BlurView } from "expo-blur";
import { Image } from "expo-image";

import { Text1, Text3 } from "~/components/text";
import { spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { hexToRGB } from "~/utils/colors";

export const Container = styled(BlurView).attrs({
  intensity: 80,
})`
  align-items: center;
  justify-content: center;
  padding: 10px ${spacers.ss6};
  margin-horizontal: ${spacers.ss6};
  margin-bottom: ${spacers.ss8};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray300};
  border-radius: 8px;
  overflow: hidden;
  background-color: ${({ theme }) => hexToRGB(theme.colors.white, 0.4)};
  ${isWeb ? "flex: 1" : ""};
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  ${!isWeb ? "flex: 1;" : ""}
`;

export const TitleBox = styled.View`
  flex: 1;
  padding-left: ${spacers.ss4};
`;

export const Title = styled(Text3)`
  font-family: MontserratSemiBold;
`;

export const ValueBox = styled.View``;

export const Value = styled(Text3)`
  font-family: MontserratBold;
  text-align: right;
`;

export const Description = styled(Text1)`
  text-align: right;
`;

export const Text = styled(Text3)`
  text-align: center;
  margin-top: ${spacers.ss4};
  margin-bottom: ${spacers.ss2};
  padding-horizontal: ${spacers.ss6};
  font-family: Montserrat;
  line-height: 22px;
`;

export const Content = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: ${isWeb ? "space-between" : "center"};
  width: 100%;
`;

export const MapContainer = styled.View`
  align-items: center;
  justify-content: center;
  min-height: 180px;
  min-width: 360px;
  ${!isWeb ? "width: 100%;" : ""}
`;

export const MapImage = styled(Image)`
  width: 100%;
  height: ${isWeb ? "220px" : "180px"};
`;
