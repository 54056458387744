export const messages = {
  title: {
    id: "share-your-story.title",
    defaultMessage: "Share Your Story",
  },
  subtitle: {
    id: "share-your-story.subtitle",
    defaultMessage: "Share Your Story",
  },
  label: {
    id: "share-your-story.label",
    defaultMessage: "Tell us more",
  },
  description: {
    id: "share-your-story.description",
    defaultMessage:
      "We'd love to hear about the impact Bible Engagement Project is having on you and your ministry!",
  },
  submit: {
    id: "share-your-story.submit",
    defaultMessage: "Submit",
  },
  thankYou: {
    id: "share-your-story.thank-you",
    defaultMessage: "Thank you for sharing your story!",
  },
  videoTooLong: {
    id: "share-your-story.video-too-long",
    defaultMessage: "Video too long. Please submit a video under 60 seconds.",
  },
};
