import styled from "styled-components/native";
import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";

import { Text2 } from "~/components/text";
import { colors, spacers } from "~/styles/theme";

export const Content = styled.View`
  padding-vertical: ${spacers.ss8};
`;

export const List = styled.FlatList``;

export const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-vertical: ${spacers.ss2};
`;

export const Text = styled(Text2)``;

export const Subtitle = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const ButtonBox = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonText = styled(Text2)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  padding-right: ${spacers.ss4};
`;

export const ButtonIcon = styled(Icon).attrs<{ isDownloaded: boolean }>(
  ({ isDownloaded }) => ({
    name: !isDownloaded ? "download" : "export-variant",
    size: 20,
  })
)`
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
