import * as React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Icon from "@expo/vector-icons/MaterialIcons";

import { BottomSheetModal } from "~/components/bottom-sheet";
import { windowHeight } from "~/utils/platform";
import { colors } from "~/styles/theme";

import { Overlay, NavigationBar, NavButton } from "./styles";
import { Content as WebViewContent } from "./content";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  modalUri: string;
  bottomSheetModalRef: React.RefObject<BottomSheetModal>;
}

export const WebViewModal = ({
  isModalOpen,
  setIsModalOpen,
  modalUri,
  bottomSheetModalRef,
}: Props) => {
  const { top } = useSafeAreaInsets();
  const snapPoints = [windowHeight - top];

  const handleClose = React.useCallback(() => {
    bottomSheetModalRef?.current?.close();
    setIsModalOpen(false);
  }, [bottomSheetModalRef, setIsModalOpen]);

  return (
    <BottomSheetModal
      ref={bottomSheetModalRef}
      index={0}
      snapPoints={snapPoints}
      backdropComponent={() => <Overlay onPress={handleClose} />}
      isOpen={isModalOpen}
      setIsOpen={setIsModalOpen}
    >
      <>
        <NavigationBar>
          <NavButton onPress={handleClose}>
            <Icon name="chevron-left" color={colors.gray600} size={26} />
          </NavButton>
          <NavButton onPress={handleClose} isLast>
            <Icon name="close" size={20} />
          </NavButton>
        </NavigationBar>

        <WebViewContent uri={modalUri} />
      </>
    </BottomSheetModal>
  );
};
