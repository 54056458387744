import * as React from "react";
import { Box, Card, Grid, Icon, IconButton, useTheme } from "@mui/material";

import { H3, Paragraph } from "~/dashboard/components/typography";
import { getCurrentStats, getIsLoadingData } from "~/state/stats/selectors";
import { useAppSelector } from "~/state/hooks";
import { formatMessage } from "~/utils/translation";

import { messages } from "./intl";

const Item = ({
  title,
  amount,
  icon,
  isLoading,
}: {
  title: string;
  amount: number;
  icon: string;
  isLoading: boolean;
}) => {
  const { palette } = useTheme();
  const textMuted = palette.text.secondary;

  return (
    <Grid key={title} item lg={3} md={6} sm={6} xs={12} marginBottom={2}>
      <Card
        elevation={3}
        sx={{
          p: {
            xs: "10px",
            md: "12px",
            lg: "16px",
          },
          display: "flex",
          opacity: isLoading ? 0.5 : 1,
        }}
      >
        <div>
          <IconButton
            size="small"
            sx={{
              padding: {
                md: "4px",
                lg: "8px",
              },
              background: "rgba(0, 0, 0, 0.01)",
            }}
          >
            <Icon sx={{ color: textMuted }}>{icon}</Icon>
          </IconButton>
        </div>
        <Box
          sx={{
            ml: {
              xs: "16px",
              md: "12px",
              lg: "8px",
            },
            width: "100%",
          }}
        >
          <H3
            sx={{
              mt: "-4px",
              fontSize: {
                xs: "18px",
                sm: "22px",
                md: "26px",
                lg: "28px",
              },
              textAlign: "left",
            }}
          >
            {amount?.toLocaleString()}
          </H3>
          <Paragraph sx={{ m: 0, color: textMuted, textAlign: "left" }}>
            {title}
          </Paragraph>
        </Box>
      </Card>
    </Grid>
  );
};

export const Cards = () => {
  const data = useAppSelector(getCurrentStats);
  const isLoading = useAppSelector(getIsLoadingData);

  const statListUsers = [
    {
      icon: "people",
      amount: data.totalUsers,
      title: formatMessage(messages.users),
    },
    {
      icon: "people",
      amount: data.totalProfiles,
      title: formatMessage(messages.profiles),
    },
    {
      icon: "people",
      amount: data.usUsers || 0,
      title: formatMessage(messages.usUsers),
    },
    {
      icon: "people",
      amount: data.kidminUsers || 0,
      title: formatMessage(messages.kidminUsers),
    },
  ];
  const statListOther = [
    {
      icon: "people",
      amount: data.activeUsers,
      title: formatMessage(messages.activeUsers),
    },
    {
      icon: "groups",
      amount: data.totalGroups,
      title: formatMessage(messages.groups),
    },
    {
      icon: "trending_up",
      amount: data.usersPerGroup,
      title: formatMessage(messages.membersPerGroup),
    },
    {
      icon: "trending_up",
      amount: data.impact,
      title: formatMessage(messages.impact),
    },
  ];
  const { palette } = useTheme();

  return (
    <div>
      <Grid container spacing={3}>
        {statListUsers.map((item) => (
          <Item key={item.title} {...item} isLoading={isLoading} />
        ))}
      </Grid>
      <Grid container spacing={3}>
        {statListOther.map((item) => (
          <Item key={item.title} {...item} isLoading={isLoading} />
        ))}
      </Grid>
    </div>
  );
};
