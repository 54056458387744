import styled from "styled-components/native";
import { Text2, Text4 } from "~/components/text";
import { TextInput } from "~/components/text-input";
import { spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";

export const Title = styled(Text4)`
  margin: ${spacers.ss6} ${spacers.ss6} 0;
  font-family: MontserratMedium;
  ${isWeb ? `padding-top: ${spacers.ss6}` : ""}
`;

export const Description = styled(Text2)`
  margin: ${spacers.ss6};
  font-family: Montserrat;
`;

export const Content = styled.View`
  flex: 1;
  margin: ${spacers.ss8} ${spacers.ss6};
`;

export const StoryInput = styled(TextInput).attrs({
  multiline: true,
  numberOfLines: 6,
})`
  min-height: 100px;
  background-color: ${({ theme }) => theme.colors.gray100};
  color: ${({ theme }) => theme.colors.black};
`;

export const SubmitButtonContainer = styled.View`
  margin: ${spacers.ss6} 0;
`;

export const UploadContainer = styled.View`
  ${isWeb ? `display:block` : ""}
`;
