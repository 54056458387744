import React from "react";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useContextMenu } from "react-contexify";
import { useTheme } from "styled-components/native";

import * as routes from "~/constants/routes";
import { PostAuthParamList } from "~/navigation/post-auth";
import { IconWithBadge } from "~/components/icon/icon-with-badge";
import {
  getEnvironment,
  getProfileImage,
  getMenuItems,
} from "~/state/user/selectors";
import { getUnreadMessagesCount } from "~/state/groups/selectors";
import { IconSizes } from "~/components/icon";
import { Avatar } from "~/components/avatar";
import { useAppSelector } from "~/state/hooks";
import { Environment } from "~/state/user/types";
import { isMobileDevice } from "~/utils/platform";

import {
  HeaderAvatar,
  HeaderIconsContainer,
  HeaderIconWrapper,
  Logo,
  Badge,
  BadgeBox,
  BadgeText,
  Container,
  MenuBox,
  MenuButton,
  MenuText,
} from "./styles";
import { BadgeButton } from "../styles";
import { messages } from "../intl";
import { getRemoteConfigValue } from "~/state/config";
import { formatMessage } from "~/utils/translation";
import { getIsDarkMode } from "~/state/settings";
import { GradientBorderView } from "~/components/gradient-border";

interface HeaderBarProps {
  iconColor?: string;
  withChatButton?: boolean;
  withLightButtons?: boolean;
  isSticky?: boolean;
  hasShadow?: boolean;
  isPreAuth?: boolean;
  onPress?: () => void;
}

type NavigationProp = NativeStackScreenProps<
  PostAuthParamList,
  "messages" | "settings.modal"
>;

export const WebHeader = ({
  withChatButton = true,
  withLightButtons = false,
  isSticky,
  isPreAuth,
  hasShadow = true,
}: HeaderBarProps) => {
  const navigation = useNavigation<NavigationProp["navigation"]>();
  const [currentScreen, setCurrentScreen] = React.useState("");
  const menuItems = useAppSelector(getMenuItems);
  const theme = useTheme();
  const isDarkMode = useAppSelector(getIsDarkMode);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("focus", (e) => {
      setCurrentScreen(e?.target || "");
    });

    return unsubscribe;
  }, [navigation]);

  const { hideAll } = useContextMenu();

  const onPress = (routeName: string) => {
    navigation.navigate(routeName);
    hideAll();
  };

  const logoRoute = isPreAuth ? routes.intro : routes.home;
  const lightLogo = require("./logo.png");
  const darkLogo = require("./logo-dark.png");
  const logoSource = isDarkMode ? darkLogo : lightLogo;

  return (
    <Container isSticky={isSticky} hasShadow={hasShadow}>
      <TouchableOpacity onPress={() => onPress(logoRoute)}>
        <Logo source={logoSource} />
      </TouchableOpacity>

      {!isPreAuth ? (
        <>
          <MenuBox>
            {menuItems.map(({ label, routeName }) => {
              const isActive = currentScreen.includes(routeName);
              return (
                <MenuButton
                  key={routeName}
                  onPress={() => onPress(routeName)}
                  isActive={isActive}
                >
                  <MenuText isActive={isActive}>{label}</MenuText>
                </MenuButton>
              );
            })}
          </MenuBox>
          <HeaderIcons
            iconColor={theme.colors.black}
            withChatButton={withChatButton}
            withLightButtons={withLightButtons}
            onPress={hideAll}
          />
        </>
      ) : !isMobileDevice ? (
        <>
          <MenuButton onPress={() => onPress(routes.login)}>
            <MenuText>{messages.login}</MenuText>
          </MenuButton>
        </>
      ) : null}
    </Container>
  );
};

export const HeaderIcons = ({
  iconColor,
  withChatButton = true,
  withLightButtons = false,
  onPress,
}: Pick<
  HeaderBarProps,
  "iconColor" | "withChatButton" | "withLightButtons" | "onPress"
>) => {
  const theme = useTheme();
  const unreadMessagesCount = useAppSelector(getUnreadMessagesCount);
  const navigation = useNavigation<NavigationProp["navigation"]>();

  const profileImage = useAppSelector(getProfileImage);
  const environment = useAppSelector(getEnvironment);

  const isGivingButtonEnabled = useAppSelector((state) =>
    getRemoteConfigValue(state, "givingButtonEnabled")
  );
  const giveMessage = formatMessage(messages.give).toUpperCase();

  const iconColors = withLightButtons
    ? theme?.colors.white
    : iconColor ?? theme?.colors.black;

  const onProfilePress = React.useCallback(() => {
    if (typeof onPress === "function") {
      onPress();
    }
    navigation.navigate(routes.settingsModal);
  }, [navigation, onPress]);

  const goToMessages = React.useCallback(() => {
    if (typeof onPress === "function") {
      onPress();
    }
    navigation.navigate(routes.messages);
  }, [navigation, onPress]);

  const onGivePress = React.useCallback(() => {
    navigation.navigate(routes.settings, { tab: "pay-it-forward" });
  }, [navigation]);

  return (
    <HeaderIconsContainer>
      {environment === Environment.Staging ? (
        <Badge color={iconColors}>
          <BadgeText color={iconColors}>STG</BadgeText>
        </Badge>
      ) : null}

      {isGivingButtonEnabled ? (
        <BadgeButton onPress={onGivePress}>
          <GradientBorderView
            style={{ borderWidth: 1, borderRadius: 9 }}
            gradientProps={{
              colors: ["#F9A95D", "#EB3230", "#C155A7"],
            }}
          >
            <BadgeBox>
              <BadgeText color={iconColors}>{giveMessage}</BadgeText>
            </BadgeBox>
          </GradientBorderView>
        </BadgeButton>
      ) : null}

      {withChatButton ? (
        <HeaderIconWrapper>
          <IconWithBadge
            badgeCount={unreadMessagesCount}
            onPress={goToMessages}
            name="chat-bubble"
            size={IconSizes.Medium}
            color={iconColors}
          />
        </HeaderIconWrapper>
      ) : null}

      <HeaderAvatar onPress={onProfilePress}>
        <Avatar isSettings size={IconSizes.Large} uri={profileImage} />
      </HeaderAvatar>
    </HeaderIconsContainer>
  );
};
