import Constants from "expo-constants";

import { WEB_GEOCODING_ENDPOINT } from "~/constants";

const getCurrentPositionAsync = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position),
      (error) => reject(error)
    );
  });
};

export const getLocation = async () => {
  if (!navigator.geolocation) {
    return;
  }
  try {
    const position = (await getCurrentPositionAsync()) as GeolocationPosition;
    const latitude = position?.coords?.latitude;
    const longitude = position?.coords?.longitude;
    const apiKey = Constants?.expoConfig?.extra?.webGeocodeApiKey;

    if (!latitude || !longitude || !apiKey) {
      return;
    }

    const response = await fetch(
      `${WEB_GEOCODING_ENDPOINT}?q=${latitude}+${longitude}&key=${apiKey}`
    );

    if (!response.ok) {
      console.error("Failed to fetch location data");
    }

    const data = await response.json();

    const result = data?.results[0];
    const { city, country, postcode } = result?.components || {};

    return { city, postalCode: postcode, country };
  } catch (e) {
    console.error(e);
    return;
  }
};
