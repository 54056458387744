export const login = "login";
export const signUp = "signUp";
export const intro = "intro";
export const forgotPassword = "forgotPassword";
export const resetPassword = "resetPassword";
export const createProfile = "createProfile";
export const locationModal = "locationModal";
export const prefixModal = "prefixModal";
export const imageModal = "imageModal";
export const payItForward = "payItForward";
export const giving = "giving";
export const inviteCode = "i"; // It's for the purpose of a short URL on web
export const auth = "__"; // It's for the purpose of the Firebase redirects

// ==== Home Navigator ====
export const home = "home";
export const homeTab = "home.tab";

// ==== Library Navigator ====
export const library = "library";
export const libraryTab = "library.tab";
export const librarySearch = "library.search";
export const libraryFilters = "library.filters";
export const libraryProgress = "library.progress";
export const plan = "plan";
export const session = "session";
export const sessionDetails = "session.details";
export const completeDay = "completeDay";
export const videoPlayer = "videoPlayer";
export const sessionModal = "sessionModal";

// ==== Bible Navigator ====
export const bible = "bible";
export const bibleTab = "bible.tab";
export const booksModal = "bible.booksModal";
export const versionModal = "bible.versionModal";

// ==== Notes Navigator ====
export const notes = "notes";
export const notesTab = "notes.tab";
export const sessionNotes = "notes.sessions";
export const bibleNotes = "notes.bible";
export const sessionNotesView = "notes.sessions.view";
export const sessionNoteView = "notes.sessions.single.view";
export const notesMigrated = "notes.notes-migrated";
export const bibleNoteAdd = "notes.bible.add";
export const bibleNoteViewEdit = "notes.bible.view-edit";
export const personalNotes = "notes.personal";
export const personalNotesAdd = "notes.personal.add";
export const personalNotesViewEdit = "notes.personal.view-edit";

// ==== Groups Navigator ====
export const groups = "groups";
export const groupsTab = "groups.tab";
export const groupResources = "group.resources";
export const groupJoin = "group.join";
export const groupModal = "group.modal";
export const groupChat = "group.chat";

export const messages = "messages";

// ==== Settings Navigator ====
export const settingsModal = "settings.modal";
export const settings = "settings";
export const appSettings = "settings.app-settings";
export const profile = "settings.profile";
export const about = "settings.about";
export const helpAndFeedback = "settings.help-and-feedback";
export const downloads = "settings.downloads";
export const changeName = "settings.change-name";
export const changeEmail = "settings.change-email";
export const changePassword = "settings.change-password";
export const deleteAccount = "settings.delete-account";
export const devTools = "settings.dev-tools";
export const reminders = "settings.reminders";
export const appFeedback = "settings.app-feedback";
export const legal = "settings.legal";
export const announcements = "settings.announcements";
export const inAppMessages = "settings.inAppMessages";
export const shareYourStory = "settings.share-your-story";
export const cookiePolicy = "cookie-policy";

// ==== Dashboard ====
export const analytics = "analytics";
