import { createAction } from "@reduxjs/toolkit";

import { GetStatsHistoryPayload, getChurchesDataPayload } from "./types";

export const getStatsHistory =
  createAction<GetStatsHistoryPayload>("getStatsHistory");

export const getChurchesData =
  createAction<getChurchesDataPayload>("getChurchesData");

export const getRecentStats = createAction("getRecentStats");
