import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { isWeb } from "~/utils/platform";
import { getRouteByPlatform, HomeContentFull } from "~/state/flamelink";

import {
  Container,
  Title,
  Description,
  ImageBox,
  CardImage,
  BottomBox,
} from "./styles";

interface Props extends HomeContentFull {
  onModalPress: (uri: string) => void;
}

export const HomeCard = React.memo<Props>(
  ({ title, description, imageUri, route, isFeatured, onModalPress }) => {
    const navigation = useNavigation();

    const routeByPlatform = getRouteByPlatform(route);
    const isExternal = route?.startsWith("http");

    const onPress = () => {
      if (!route) {
        return;
      }
      if (isExternal) {
        onModalPress(route);
        return;
      }
      const routeName = routeByPlatform?.route;
      const routeOptions = routeByPlatform?.options || {};

      if (routeName) {
        // @ts-ignore
        navigation.navigate(routeName, routeOptions);
      }
    };

    const isWebFeatured = isWeb && isFeatured;

    return (
      <Container isWebFeatured={isWebFeatured}>
        {!isWebFeatured ? (
          <>
            {title ? <Title>{title}</Title> : null}
            {description ? <Description>{description}</Description> : null}
          </>
        ) : null}
        {imageUri ? (
          <ImageBox onPress={onPress}>
            <CardImage
              source={{ uri: imageUri }}
              style={{ width: "100%", height: 200 }}
            />
          </ImageBox>
        ) : null}
        {isWebFeatured ? (
          <BottomBox>
            {title ? <Title>{title}</Title> : null}
            {description ? <Description>{description}</Description> : null}
          </BottomBox>
        ) : null}
      </Container>
    );
  }
);
