import styled from "styled-components/native";
import { Text2 } from "~/components/text";
import { spacers } from "~/styles/theme";

export const Container = styled.View`
  flex: 1;
`;

export const UploadContainer = styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: ${spacers.ss6};
`;

export const UploadedContainer = styled.Pressable`
  padding-horizontal: ${spacers.ss6};
  flex-direction: row;
`;

export const AvatarContainer = styled.View`
  margin-right: 16px;
`;

export const UploadButton = styled.TouchableOpacity`
  flex: 1;
  height: 120px;
  border-radius: 8px;
  border-width: 1px;
  border-style: dashed;
  border-color: ${({ theme }) => theme.colors.gray600};
  align-items: center;
  justify-content: center;
`;

export const VideoInfoContainer = styled.View`
  flex: 1;
  justify-content: center;
`;

export const ThumbnailImage = styled.Image`
  width: 90px;
  height: 90px;
  border-radius: 8px;
`;

export const VideoName = styled(Text2).attrs({
  numberOfLines: 1,
})`
  margin-bottom: 4px;
`;

export const MetaText = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray600};
`;
