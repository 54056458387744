import { SagaIterator } from "redux-saga";
import { call, select, takeLatest } from "redux-saga/effects";
import { storage, database } from "<config>/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { format } from "date-fns";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import { handleError } from "~/utils/logger";
import {
  getEnvironment,
  getUserEmail,
  getUserFullName,
  getUserId,
} from "~/state/user/selectors";
import { getUserStoriesCollection } from "~/constants/collections";

import { addAppFeedback, submitUserStory } from "./actions";
import { sendAppFeedback } from "./side-effects";
import type { AddAppFeedbackAction, SendStoryAction } from "./types";
import { SLACK_WEBHOOK_URL } from "./constants";

function* handleAddAppFeedback({
  payload,
}: AddAppFeedbackAction): SagaIterator {
  try {
    const userId = yield select(getUserId);
    const email = yield select(getUserEmail);
    const env: ReturnType<typeof getEnvironment> = yield select(getEnvironment);

    yield call(sendAppFeedback, { payload, env, userId, email });
    yield call(payload.onCompleted);
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* appFeedbackSaga() {
  yield takeLatest(addAppFeedback.type, handleAddAppFeedback);
}

const uploadFileToFirebase = async (
  fileUri: string,
  filePath: string,
  contentType: string,
  onProgress: (progress: number) => void
) => {
  const storageRef = ref(storage, filePath);
  try {
    const response = await fetch(fileUri);
    const blob = await response.blob();

    const uploadTask = uploadBytesResumable(storageRef, blob, { contentType });

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
          console.log(`${filePath} upload is ${progress}% done`);
        },
        (error) => reject(error),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(downloadURL);
        }
      );
    });
  } catch (error) {
    throw new Error(`Upload failed: ${JSON.stringify(error)}`);
  }
};

const uploadToFirebase = async (
  videoUri: string,
  thumbnailUri: string,
  userName: string,
  onProgress: (progress: number) => void
) => {
  try {
    const timestamp = Date.now();
    const timeFormatted = format(timestamp, "yyyy-MM-dd-hh-mm");
    const userString = userName.replace(" ", "-");
    const videoPath = `stories/${timeFormatted}-${userString}.mp4`;
    const thumbnailPath = `stories/thumbnails/${timeFormatted}-${userString}.jpg`;

    const [videoUrl, thumbnailUrl] = await Promise.all([
      uploadFileToFirebase(videoUri, videoPath, "video/mp4", () => {}),
      uploadFileToFirebase(
        thumbnailUri,
        thumbnailPath,
        "image/jpeg",
        onProgress
      ),
    ]);

    return { videoUrl, thumbnailUrl };
  } catch (error) {
    throw new Error(`Upload failed: ${JSON.stringify(error)}`);
  }
};

const sendToSlack = async (
  userName: string,
  videoUrl: string,
  storyText: string
) => {
  const message = {
    blocks: [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `🎥 *New Story Uploaded* 🚀\n👤 *User:* ${userName}\n📜 *Story:* ${storyText}`,
        },
      },
      {
        type: "actions",
        elements: [
          {
            type: "button",
            text: {
              type: "plain_text",
              text: "🎬 Watch Video",
              emoji: true,
            },
            url: videoUrl,
            action_id: "watch_video",
            style: "primary",
          },
        ],
      },
    ],
  };

  try {
    await fetch(SLACK_WEBHOOK_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(message),
    });
  } catch (error) {
    throw new Error(`Slack notification failed: ${JSON.stringify(error)}`);
  }
};

function* handleSubmitUserStory({
  payload: { videoUri, thumbnailUri, text, onProgress, onSuccess, onError },
}: SendStoryAction): SagaIterator {
  try {
    const email = yield select(getUserEmail);
    const userName = yield select(getUserFullName);
    const env = yield select(getEnvironment);
    const storyCollection = getUserStoriesCollection(env);

    const userString = `${userName} (${email})`;

    if (!videoUri) {
      throw new Error("No video URI provided.");
    }

    const { videoUrl, thumbnailUrl } = yield call(
      uploadToFirebase,
      videoUri,
      thumbnailUri,
      userName,
      onProgress
    );

    // @ts-ignore
    yield call(addDoc, collection(database, storyCollection), {
      userName,
      email,
      videoUrl,
      thumbnailUrl,
      text,
      createdAt: serverTimestamp(),
    });

    yield call(sendToSlack, userString, videoUrl, text);

    if (onSuccess) onSuccess();
  } catch (e) {
    yield call(handleError, e);
    if (onError) {
      yield call(onError);
    }
  }
}

export function* submitUserStorySaga() {
  yield takeLatest(submitUserStory.type, handleSubmitUserStory);
}
