export const messages = {
  appFeedback: {
    id: "profile.ask-for-feedback.description",
    defaultMessage:
      "Are you enjoying\nBible Engagement Project?\nLeave us feedback...",
  },
  myResources: {
    id: "home.my-resources",
    defaultMessage: "My Resources",
  },
};
