export const messages = {
  videoChangeSuccess: {
    id: "share-your-story.video-upload.success",
    defaultMessage: "The video has been successfully uploaded!",
  },
  videoChangeError: {
    id: "share-your-story.video-upload.error",
    defaultMessage: "Something went wrong. Please try again later.",
  },
  uploadVideo: {
    id: "share-your-story.video-upload.upload-video",
    defaultMessage: "Upload video (max 60 seconds)",
  },
  changeVideo: {
    id: "share-your-story.video-upload.change-video",
    defaultMessage: "Change video",
  },
  uploading: {
    id: "share-your-story.video-upload.uploading",
    defaultMessage: "Uploading... ({{progress}}% done)",
  },
};
