import * as React from "react";
import { useTheme } from "styled-components/native";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { Icon, IconTypes } from "~/components/icon";
import { getRecentStats } from "~/state/stats/actions";
import { getGlobalImpact } from "~/state/stats/selectors";
import { getIsDarkMode } from "~/state/settings/selectors";

import {
  Container,
  TitleBox,
  Title,
  ValueBox,
  Value,
  Description,
  TitleContainer,
  Content,
  MapContainer,
  MapImage,
  Text,
} from "./styles";
import { messages } from "./intl";

export const GlobalImpact = () => {
  const data = useAppSelector(getGlobalImpact);
  const isDarkMode = useAppSelector(getIsDarkMode);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  React.useEffect(() => {
    dispatch(getRecentStats());
  }, [dispatch]);

  if (!data) {
    return;
  }

  const blurTint = isDarkMode ? "dark" : "light";

  return (
    <Container tint={blurTint}>
      <Content>
        <TitleContainer>
          <Icon type={IconTypes.Globe} size={18} color={theme.colors.black} />
          <TitleBox>
            <Title>{messages.title}</Title>
          </TitleBox>
        </TitleContainer>

        <ValueBox>
          <Value>{data}</Value>
          <Description>{messages.description}</Description>
        </ValueBox>
      </Content>
      <MapContainer>
        <MapImage
          source={require("./resources/impact-map.png")}
          contentFit="contain"
        />
      </MapContainer>
      <Text>{messages.text}</Text>
    </Container>
  );
};
