import { SessionProps } from "~/screens/session/types";
import type {
  AudiencesContent,
  LibraryFiltersOptions,
  PlansContent,
  SessionsContent,
} from "./types";
import AsyncStorage from "@react-native-async-storage/async-storage";

import * as appRoutes from "~/constants/routes/appRoutes";
import * as webRoutes from "~/constants/routes/webRoutes";
import { isAndroid, isWeb } from "~/utils/platform";
import { handleError } from "~/utils/logger";

const baseStorageUrl =
  "https://firebasestorage.googleapis.com/v0/b/bep-rc.appspot.com/o/";
const mediaFolder = "flamelink/media/sized/";
const downloadableFilesFolder = "flamelink/media/";

export enum MediaSize {
  Small = "Small",
  Medium = "Medium",
  Large = "Large",
  ExtraLarge = "ExtraLarge",
}

const getSizePath = (size?: MediaSize) => {
  switch (size) {
    case MediaSize.Small:
      return "667_9999_100/";
    case MediaSize.Medium:
      return "900_9999_100/";
    case MediaSize.Large:
      return "1440_9999_100/";
    case MediaSize.ExtraLarge:
      return "1920_9999_100/";
    default:
      return "900_9999_100/";
  }
};

export const makeMediaUrl = (mediaPath: string, size?: MediaSize) =>
  `${baseStorageUrl}${encodeURIComponent(
    `${mediaFolder}${getSizePath(size)}${mediaPath}`
  )}?alt=media`;

export const makeDownloadUrl = (mediaPath: string) =>
  `${baseStorageUrl}${encodeURIComponent(
    `${downloadableFilesFolder}${mediaPath}`
  )}?alt=media`;

const mapLocalizedFilterValues = (
  filterKey: "audiences" | "types",
  filterValues: string[],
  libraryFiltersOptions: LibraryFiltersOptions
) =>
  filterValues
    .map((value) =>
      flattenLocalizedFilterValues(libraryFiltersOptions[filterKey], value)
    )
    .flatMap((values) => values);

const flattenLocalizedFilterValues = (
  filter: LibraryFiltersOptions["audiences"] | LibraryFiltersOptions["types"],
  value: string
) =>
  Object.keys(filter)
    .map((lang) =>
      filter[lang]
        .filter((item) => item.value === value)
        .map((filteredItem) => filteredItem.title.toLowerCase())
    )
    .flat();

export const libraryLanguageFilter =
  (languagesToDisplay: string[]) => (audience: AudiencesContent) =>
    languagesToDisplay.some((language) => audience.locale.includes(language));

export const libraryAudienceFilter =
  (
    audienceLibraryFilter: string[],
    libraryFiltersOptions: LibraryFiltersOptions
  ) =>
  (audience: AudiencesContent) =>
    mapLocalizedFilterValues(
      "audiences",
      audienceLibraryFilter,
      libraryFiltersOptions
    ).some((filter) =>
      new RegExp(`\\b${filter}\\b`, "i").test(audience.title.toLowerCase())
    );

export const libraryTypefilter =
  (
    plans: PlansContent[],
    typeLibraryFilter: string[],
    libraryFiltersOptions: LibraryFiltersOptions
  ) =>
  (audience: AudiencesContent) => {
    const filteredPlans = audience.plans.filter((plan) => {
      const planType = plans.find((p) => p.id === plan)?.contentType;

      if (!planType) return false;

      return mapLocalizedFilterValues(
        "types",
        typeLibraryFilter,
        libraryFiltersOptions
      ).includes(planType);
    });

    return { ...audience, plans: filteredPlans };
  };

export const audienceHasPlansFilter = (audience: AudiencesContent) =>
  audience.plans.length > 0;

export const extractInitialFilters = (
  libraryFiltersOptions: LibraryFiltersOptions
) => {
  const baseLang = "en";
  const audiences = libraryFiltersOptions?.audiences[baseLang].map(
    (audience) => audience.value
  );
  const types = libraryFiltersOptions?.types[baseLang].map(
    (type) => type.value
  );
  const progress = libraryFiltersOptions.progress[baseLang].map((p) => p.value);

  return { audiences, types, progress };
};

/**
 * Function that determines how many items are in the session, next to the group day.
 * @TODO: for a more streamlined logic, we should rethink on the calculation before adding the records.
 */
export const getSessionItemsLength = (sessionData?: SessionsContent) =>
  sessionData?.days?.length ||
  sessionData?.sessions?.length ||
  sessionData?.lessons.length ||
  0;

interface GetSessionDataToOpenParams {
  sessionIndex: number;
  volumeIndex: number;
  dayIndex: number;
  sessionSourceData?: SessionsContent;
  isNextOrPrev?: boolean;
}

export const getSessionDataToOpen = ({
  sessionIndex,
  volumeIndex,
  dayIndex,
  sessionSourceData,
  isNextOrPrev,
}: GetSessionDataToOpenParams): Pick<
  SessionProps["route"]["params"],
  "lessonId" | "sessionIndex" | "volumeIndex" | "dayIndex" | "childSessionId"
> => {
  const lessonId = sessionSourceData?.lessons?.[dayIndex];
  const childSessionId = sessionSourceData?.sessions?.[dayIndex];

  if (lessonId) {
    return { lessonId, sessionIndex, volumeIndex, dayIndex: 0 };
  }

  if (childSessionId) {
    // the purpose of this is to open the first day of the child session as we will only use the "group" day
    return { childSessionId, sessionIndex, volumeIndex, dayIndex: 0 };
  }

  const getInitialDayIndex = () => {
    if (isNextOrPrev) {
      return sessionSourceData?.volumeHeading ? 0 : 1;
    }

    return dayIndex;
  };

  return { sessionIndex, volumeIndex, dayIndex: getInitialDayIndex() };
};

export const getSessionConditions = (session?: SessionsContent) => {
  // if a session has lessons or child sessions, it should skip the last day check
  if (session?.lessons?.length || session?.sessions?.length) {
    return {
      shouldOmitFirstDayCheck: false,
      shouldOmitLastDayCheck: true,
    };
  }

  // if a session has days, but it doesn't have group day, it should skip the first day check
  if (!session?.volumeHeading && session?.days?.length) {
    return {
      shouldOmitFirstDayCheck: true,
      shouldOmitLastDayCheck: false,
    };
  }

  return {
    shouldOmitFirstDayCheck: false,
    shouldOmitLastDayCheck: false,
  };
};

// Helper function allowing split the store into different keys to avoid Android storage limit
export const saveLocalFlamelinkData = async (key: string, data: any) => {
  if (!isAndroid) {
    return;
  }
  await AsyncStorage.setItem(key, JSON.stringify(data));
};

export const getLocalFlamelinkData = async (key: string) => {
  if (!isAndroid) {
    return;
  }
  try {
    const data = await AsyncStorage.getItem(key);
    if (data) {
      return JSON.parse(data);
    }
  } catch (e) {
    handleError(e);
    return false;
  }
};

const tabStacks = {
  "library.tab": [
    appRoutes.library,
    appRoutes.librarySearch,
    appRoutes.libraryFilters,
    appRoutes.libraryProgress,
    appRoutes.plan,
    appRoutes.session,
    appRoutes.sessionDetails,
  ],
  "bible.tab": [appRoutes.bible],
  "notes.tab": [
    appRoutes.notes,
    appRoutes.sessionNotes,
    appRoutes.bibleNotes,
    appRoutes.personalNotes,
  ],
  "groups.tab": [appRoutes.groups, appRoutes.groupChat],
  settings: [appRoutes.profile, appRoutes.about],
};

export const getRouteByPlatform = (
  route?: string
): { route: string; options: { [key: string]: string } } | null => {
  const routes = !isWeb ? appRoutes : webRoutes;

  if (!route) {
    return null;
  }

  const [path, params] = route.split("?");
  const pathParts = path.split("/");

  if (!isWeb) {
    for (const tabStackKey in tabStacks) {
      const tabStack = (tabStacks as any)[tabStackKey];
      if (tabStack.includes(pathParts[0]) || tabStack.includes(path)) {
        const options: { [key: string]: any } = {};
        if (params) {
          params.split("&").forEach((param) => {
            const [key, value] = param.split("=");
            options[key] = value;
          });
        }

        return {
          route: tabStackKey,
          options: {
            screen:
              pathParts.length > 1 ? pathParts[pathParts.length - 1] : path,
            params: options as any,
          },
        };
      }
    }
  }

  for (const routeKey in routes) {
    if (
      path === routeKey ||
      path.startsWith(routeKey + "-") ||
      path === routeKey.replace(/-/g, ".")
    ) {
      const options: { [key: string]: any } = {};
      if (params) {
        params.split("&").forEach((param) => {
          const [key, value] = param.split("=");
          options[key] = value;
        });
      }
      if (params.indexOf(webRoutes.payItForward) && !isWeb) {
        return {
          route: appRoutes.payItForward,
          options,
        };
      }
      return { route: (routes as any)[routeKey], options };
    }
  }

  return null;
};
