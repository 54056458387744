import React from "react";
import Icon from "@expo/vector-icons/MaterialIcons";
import { Animated } from "react-native";

import * as routes from "~/constants/routes";
import {
  Card,
  CardImage,
  CardContent,
  Headline,
  Description,
  ActionsContainer,
  ActionButton,
  ButtonText,
  GroupBox,
  GroupName,
  MetaBox,
  MetaText,
  ActiveItemWrapper,
  AvatarBox,
  AvatarContainer,
  DeleteButton,
} from "./styles";
import { messages } from "./intl";
import { useAppSelector } from "~/state/hooks";
import {
  getDevotionsBySessionId,
  getInitialVolumeId,
  getLessonsBySessionId,
  getNextSessionId,
  getPlanById,
  getPlanDaysCount,
  getPlanThumbnail,
  getPrevSessionId,
  getSessionById,
  getSessionsBySessionId,
  getVolumeById,
  getVolumeSessionsCount,
  PersonalDevotionsContent,
} from "~/state/flamelink";

import { getCardWidth } from "./utils";
import { useNavigation } from "@react-navigation/native";
import { navigateTo } from "~/utils/navigation";
import {
  getNormalizedProgressByPlanId,
  ProgressState,
  SessionProgress,
  useDeletePlanProgressMutation,
} from "~/state/content-progress";
import { GradientBorderView } from "~/components/gradient-border";
import { messages as planMessages } from "~/screens/plan/intl";
import { formatMessage } from "~/utils/translation";
import { extractNumber } from "~/utils/strings";
import { AvatarSize } from "~/components/group-avatar/types";
import { GroupAvatar } from "~/components/group-avatar";
import { getFixedItems } from "~/utils/arrays";
import {
  getGroupsWithPlanId,
  getNewestGroupWithPlanId,
} from "~/state/groups/selectors";
import { colors } from "~/styles/theme";
import { useAlert } from "~/components/alert";

interface Props {
  planProgressId: string;
  userId: string;
  planId: string;
  isCompleted: boolean;
  dateCompleted?: number | undefined;
  currentSection?: number | undefined;
  sessions?: SessionProgress[];
  activeSession: SessionProgress;
  size: number;
}

interface ButtonProps {
  planId?: string;
  sessionId?: string;
  volumeId?: string;
  isActive?: boolean;
  type: string;
  title?: string;
}

const ResourceButton = ({
  planId,
  volumeId,
  sessionId,
  isActive,
  type,
  title: defaultTitle,
}: ButtonProps) => {
  const navigation = useNavigation<any>();

  const session = useAppSelector((state) =>
    getSessionById(state, sessionId || "")
  );

  const getTitleByType = (id: string) => {
    switch (id) {
      case "session":
        return session?.title;
      case "downloads":
        return formatMessage(messages.downloads);
      case "toc":
        return formatMessage(messages.more);
    }
  };

  const handlePlanPress = React.useCallback(() => {
    navigateTo(navigation, routes.libraryTab, {
      screen: routes.plan,
      params: { planId, backToHome: true },
      initial: false,
    });
  }, [navigation, planId]);

  const handleResourcePress = React.useCallback(() => {
    navigateTo(navigation, routes.libraryTab, {
      screen: routes.plan,
      params: { planId, volumeId, sessionId, backToHome: true },
      initial: false,
    });
  }, [navigation, planId, volumeId, sessionId]);

  const handleDownloadsPress = React.useCallback(() => {
    navigateTo(navigation, routes.libraryTab, {
      screen: routes.plan,
      params: { planId, volumeId, openDownloads: true, backToHome: true },
      initial: false,
    });
  }, [navigation, planId, volumeId]);

  const getFunctionByType = (id: string) => {
    switch (id) {
      case "toc":
        return handlePlanPress;
      case "session":
        return handleResourcePress;
      case "day":
        return handleResourcePress;
      case "downloads":
        return handleDownloadsPress;
    }
  };

  const title = defaultTitle || getTitleByType(type);
  const onPress = getFunctionByType(type);

  if (!title) {
    return;
  }

  if (isActive) {
    return (
      <ActiveItemWrapper>
        <GradientBorderView
          style={{ borderWidth: 1, borderRadius: 9 }}
          gradientProps={{
            colors: ["#F9A95D", "#EB3230", "#C155A7"],
          }}
        >
          <ActionButton isActive onPress={onPress}>
            <ButtonText numberOfLines={1}>{title}</ButtonText>
          </ActionButton>
        </GradientBorderView>
      </ActiveItemWrapper>
    );
  }

  return (
    <ActionButton isActive={false} onPress={onPress}>
      <ButtonText numberOfLines={1}>{title}</ButtonText>
    </ActionButton>
  );
};

const SessionCards = ({
  activeSession,
  nextSessionId,
  prevSessionId,
}: {
  activeSession: SessionProgress;
  nextSessionId?: string;
  prevSessionId?: string;
}) => {
  const items = [];
  if (nextSessionId && !prevSessionId) {
    items.push(
      <ResourceButton
        key="next-session"
        type="session"
        {...activeSession}
        sessionId={nextSessionId}
      />
    );
  }

  if (activeSession) {
    items.push(
      <ResourceButton
        key="active-session"
        type="session"
        isActive
        {...activeSession}
      />
    );
  }

  if (prevSessionId) {
    items.push(
      <ResourceButton
        key="prev-session"
        type="session"
        {...activeSession}
        sessionId={prevSessionId}
      />
    );
  }

  return items;
};

const getDevotionCards = ({
  activeSession,
  daysProgress = [],
  days,
  maxItems = 3,
}: {
  activeSession: SessionProgress;
  daysProgress: SessionProgress["days"];
  days: PersonalDevotionsContent[];
  maxItems: number;
}) => {
  const lastInProgressIndex = daysProgress.reduceRight(
    (acc, day, index) =>
      acc === -1 && day.state === ProgressState.InProgress ? index : acc,
    -1
  );
  const lastCompleteIndex = daysProgress.reduceRight(
    (acc, day, index) =>
      acc === -1 && day.state === ProgressState.Completed ? index : acc,
    -1
  );

  const lastIndex = Math.max(lastInProgressIndex, lastCompleteIndex);

  const daysInSession = getFixedItems(days, lastIndex, maxItems);

  return daysInSession.map((day) => {
    const index = extractNumber(day.title);
    const activeIndex = lastIndex + 1;

    if (!index) {
      return null;
    }

    const isActive = index === activeIndex && maxItems > 1;
    const dayTitle = day.title.split(":")?.[1]?.trim() || "";
    const formattedTitle = formatMessage({
      ...messages.devotionDayTitle,
      values: { index, title: dayTitle },
    });
    const title = dayTitle ? formattedTitle : day.title;

    return (
      <ResourceButton
        key={day.id}
        type="day"
        {...activeSession}
        isActive={isActive}
        title={title}
      />
    );
  });
};

const getSessionDays = (
  session: SessionProgress,
  skipFirstDay: boolean,
  skipLastDay: boolean
) => {
  if (skipLastDay) {
    return session?.days?.slice(0, -1);
  }
  if (skipFirstDay) {
    return session?.days?.slice(1);
  }
  return session?.days;
};

export const ResourceItem = React.memo(
  ({ planId, activeSession, size }: Props) => {
    const planData = useAppSelector((state) => getPlanById(state, planId));
    const planThumbnail = useAppSelector((state) =>
      getPlanThumbnail(state, planId)
    );
    const volumeData = useAppSelector((state) =>
      getVolumeById(state, activeSession.volumeId)
    );
    const sessionData = useAppSelector((state) =>
      getSessionById(state, activeSession.sessionId)
    );
    const groupData = useAppSelector((state) =>
      getNewestGroupWithPlanId(state, planId)
    );
    const totalSessions = useAppSelector((state) =>
      getVolumeSessionsCount(state, planId)
    );
    const progressData = useAppSelector((state) =>
      getNormalizedProgressByPlanId(state, planId)
    );
    const totalDays = useAppSelector((state) =>
      getPlanDaysCount(state, planId)
    );
    const nextSessionId = useAppSelector((state) =>
      getNextSessionId(state, planId, activeSession.sessionId)
    );
    const prevSessionId = useAppSelector((state) =>
      getPrevSessionId(state, activeSession.volumeId, activeSession.sessionId)
    );
    const days = useAppSelector((state) =>
      getDevotionsBySessionId(state, activeSession.sessionId)
    );
    const lessons = useAppSelector((state) =>
      getLessonsBySessionId(state, activeSession.sessionId)
    );
    const sessionSessions = useAppSelector((state) =>
      getSessionsBySessionId(state, activeSession.sessionId)
    );
    const groups = useAppSelector((state) =>
      getGroupsWithPlanId(state, planId)
    );
    const initialVolumeId = useAppSelector((state) =>
      getInitialVolumeId(state, planId)
    );

    const { showAlert } = useAlert();
    const [
      deletePlanProgress,
      { isLoading: isDeletingPlanProgress, isSuccess: isDeleteSuccess },
    ] = useDeletePlanProgressMutation();

    const animatedWidth = React.useRef(new Animated.Value(1)).current;
    const animatedOpacity = React.useRef(new Animated.Value(1)).current;

    const cardWidth = getCardWidth(size === 1);

    React.useEffect(() => {
      if (isDeleteSuccess) {
        Animated.parallel([
          Animated.timing(animatedWidth, {
            toValue: -cardWidth / 2,
            duration: 200,
            useNativeDriver: true,
          }),
          Animated.timing(animatedOpacity, {
            toValue: 0,
            duration: 150,
            useNativeDriver: true,
          }),
        ]).start();
      }
    }, [isDeleteSuccess, animatedWidth, animatedOpacity, cardWidth]);

    const renderGroups = React.useCallback(() => {
      const maxGroupsToShow = 4;
      const remainingGroupsCount = Math.max(groups.length - maxGroupsToShow, 0);

      const renderedGroups = groups
        .slice(0, maxGroupsToShow)
        .map(({ id, name, imageUri }) => (
          <AvatarBox key={id}>
            <GroupAvatar text={name} uri={imageUri} size={AvatarSize.XSmall} />
          </AvatarBox>
        ));

      if (remainingGroupsCount > 0) {
        return (
          <AvatarContainer>
            {renderedGroups}

            <AvatarBox key={"remaining-groups"}>
              <GroupAvatar
                count={remainingGroupsCount}
                size={AvatarSize.XSmall}
              />
            </AvatarBox>
          </AvatarContainer>
        );
      }

      return <AvatarContainer>{renderedGroups}</AvatarContainer>;
    }, [groups]);

    const hasChildSessions = (sessionData?.sessions?.length ?? 0) > 0;
    const hasGroupDay = !!sessionData?.volumeHeading;
    const hasSessions =
      !days.length && !lessons.length && sessionSessions.length;
    const showSessionCards = !!sessionData?.volumeHeading || !!lessons.length;
    const showDevoCards = days.length || hasSessions;

    const items = [];

    if (showSessionCards) {
      items.push(<SessionCards activeSession={activeSession} />);
    }

    if (showDevoCards) {
      const content = hasSessions ? sessionSessions : days;
      const daysProgress = getSessionDays(
        activeSession,
        !hasGroupDay,
        hasChildSessions
      );
      const devotionCards = getDevotionCards({
        activeSession,
        daysProgress,
        days: content as PersonalDevotionsContent[],
        maxItems: showSessionCards ? 1 : 2,
      });
      items.push(...devotionCards);
    }

    if (items.length === 1) {
      items.push(
        <ResourceButton {...activeSession} key="downloads" type="downloads" />
      );
    }

    if (items.length < 3) {
      items.push(
        <ResourceButton
          {...activeSession}
          volumeId={initialVolumeId}
          key="toc"
          type="toc"
        />
      );
    }

    const volumeTitle = volumeData?.title
      ? formatMessage({
          ...messages.volumeTitle,
          values: {
            index: volumeData?.volumeNumber,
            title: volumeData.title,
          },
        })
      : "";
    const title = volumeTitle || sessionData?.title;
    const displayItems = items.filter((item) => !!item);

    const handleResetProgress = React.useCallback(() => {
      if (isDeletingPlanProgress) {
        return;
      }

      showAlert(
        formatMessage(planMessages.resetPlanProgress),
        formatMessage(planMessages.resetConfirm),
        [
          {
            text: formatMessage(planMessages.cancel),
            style: "cancel",
          },
          {
            text: formatMessage(planMessages.resetButton),
            style: "destructive",
            onPress: () => {
              deletePlanProgress({ planId });
            },
          },
        ]
      );
    }, [planId, showAlert, deletePlanProgress, isDeletingPlanProgress]);

    return (
      <Animated.View
        style={{
          transform: [
            {
              translateX: animatedWidth,
            },
          ],
          opacity: animatedOpacity,
          overflow: "hidden",
        }}
      >
        <Card cardWidth={cardWidth} isLoading={isDeletingPlanProgress}>
          <DeleteButton onPress={handleResetProgress}>
            <Icon name="close" size={16} color={colors.white} />
          </DeleteButton>
          <CardImage
            source={{ uri: planThumbnail }}
            cardWidth={cardWidth}
            resizeMode="cover"
          />
          <CardContent>
            {planData?.displayTitle ? (
              <Headline>{planData.displayTitle}</Headline>
            ) : null}
            {title ? (
              <Description numberOfLines={1}>{title}</Description>
            ) : null}
            <MetaBox>
              <MetaText>
                {formatMessage(planMessages.planProgress, {
                  progress: totalDays
                    ? progressData?.numberOfDaysCompleted ?? 0 / totalDays
                    : 0,
                })}
              </MetaText>
              <MetaText>
                {formatMessage(planMessages.totalSessions, {
                  total: totalSessions,
                })}
              </MetaText>
            </MetaBox>
            <ActionsContainer>{displayItems}</ActionsContainer>
            {groupData ? renderGroups() : null}
          </CardContent>
        </Card>
      </Animated.View>
    );
  }
);
