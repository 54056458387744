export enum CameraModes {
  PICTURE = "PICTURE",
}

export enum PickerResourceType {
  IMAGE = "image",
  VIDEO = "video",
}

export type Size = {
  width: number;
  height: number;
};
