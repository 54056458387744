import styled from "styled-components/native";

import { spacers, colors } from "~/styles/theme";
import { Text1, Text5 } from "~/components/text";
import { isWeb } from "~/utils/platform";

export const Container = styled.TouchableOpacity`
  padding-horizontal: ${spacers.ss9};
  padding-vertical: ${isWeb ? spacers.ss11 : spacers.ss9};
  align-items: center;
  flex: 1;
  ${isWeb && "flex-basis: auto;"}
`;

export const Content = styled(Text5)`
  text-align: center;
  font-family: MontserratMedium;
  margin-bottom: ${spacers.ss5};
`;

export const Passage = styled(Text1)<{ isDarkMode: boolean }>`
  text-align: center;
  font-family: Montserrat;
  color: ${({ isDarkMode }) => (isDarkMode ? colors.white : colors.black)};
`;
