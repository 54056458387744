import * as React from "react";
import Svg, { Path } from "react-native-svg";
const SvgGlobe = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <Path
      fill="currentColor"
      d="M10 20a9.7 9.7 0 0 1-3.875-.788 10.1 10.1 0 0 1-3.187-2.15 10.1 10.1 0 0 1-2.15-3.187A9.7 9.7 0 0 1 0 10q0-2.075.788-3.887a10.2 10.2 0 0 1 2.15-3.176A10.1 10.1 0 0 1 6.124.788 9.7 9.7 0 0 1 10 0q2.076 0 3.887.788a10.2 10.2 0 0 1 3.175 2.15 10.2 10.2 0 0 1 2.15 3.174A9.65 9.65 0 0 1 20 10a9.7 9.7 0 0 1-.788 3.875 10.1 10.1 0 0 1-2.15 3.188 10.2 10.2 0 0 1-3.175 2.15A9.65 9.65 0 0 1 10 20m0-2.05q.65-.9 1.125-1.875T11.9 14H8.1q.3 1.1.775 2.075T10 17.95m-2.6-.4q-.45-.824-.788-1.713A15 15 0 0 1 6.05 14H3.1a8.3 8.3 0 0 0 1.812 2.175A7.2 7.2 0 0 0 7.4 17.55m5.2 0a7.2 7.2 0 0 0 2.488-1.375A8.3 8.3 0 0 0 16.9 14h-2.95q-.225.95-.562 1.838a14 14 0 0 1-.788 1.712M2.25 12h3.4a13.208 13.208 0 0 1-.15-2 13 13 0 0 1 .15-2h-3.4A7.959 7.959 0 0 0 2 10a8 8 0 0 0 .25 2m5.4 0h4.7q.075-.5.113-.988.037-.487.037-1.012a13 13 0 0 0-.15-2h-4.7q-.075.5-.113.988Q7.5 9.475 7.5 10a13 13 0 0 0 .15 2m6.7 0h3.4a7.953 7.953 0 0 0 .25-2 8 8 0 0 0-.25-2h-3.4q.075.5.113.988.037.487.037 1.012a13 13 0 0 1-.15 2m-.4-6h2.95a8.3 8.3 0 0 0-1.812-2.175A7.2 7.2 0 0 0 12.6 2.45q.45.825.787 1.712.338.888.563 1.838M8.1 6h3.8q-.3-1.1-.775-2.075A12.7 12.7 0 0 0 10 2.05q-.65.9-1.125 1.875T8.1 6m-5 0h2.95q.225-.95.563-1.838.337-.887.787-1.712Q6 2.9 4.912 3.825A8.3 8.3 0 0 0 3.1 6"
    />
  </Svg>
);
export default SvgGlobe;
