import * as React from "react";
import { Animated, StyleSheet, TouchableOpacity } from "react-native";
import Icon from "@expo/vector-icons/MaterialIcons";
import { useTheme } from "styled-components/native";
import { useFocusEffect } from "@react-navigation/native";

import { BackButton } from "~/components/back-button";
import { useFontSize } from "~/utils/hooks/use-font-size";

import {
  Container,
  Content,
  Background,
  LabelBox,
  Label,
  LabelText,
  BottomBox,
  MetaBox,
  MetaText,
  MetaTextBold,
} from "./styles";
import { messages } from "./intl";
import { ComponentProps } from "./types";

const getAnimationProps = (useNativeDriver: boolean) => ({
  duration: 500,
  useNativeDriver,
});

const startColor = "#FFEBFC";
const stopColor = "#FFF1BE";

export const BibleHeaderComponent = React.memo<ComponentProps>(
  ({
    book,
    version,
    scripture,
    isCompact,
    showBackButton,
    isSession,
    openBooksModal,
    openVersionModal,
    handleBackPress,
    onPress,
  }) => {
    const [isHeaderFixed, setIsHeaderFixed] = React.useState(false);
    const fadeAnim = React.useRef(new Animated.Value(1));
    const resizeAnim = React.useRef(new Animated.Value(80));

    const { selectFontSizeIncrementally } = useFontSize();
    const theme = useTheme();

    const fadeIn = React.useCallback(
      () =>
        Animated.timing(fadeAnim.current, {
          toValue: 1,
          ...getAnimationProps(true),
        }).start(),
      []
    );

    const fadeOut = React.useCallback(
      () =>
        Animated.timing(fadeAnim.current, {
          toValue: 0,
          ...getAnimationProps(true),
        }).start(),
      []
    );

    const squeeze = React.useCallback(
      () =>
        Animated.timing(resizeAnim.current, {
          toValue: scripture && !isHeaderFixed ? 0 : 40,
          ...getAnimationProps(false),
        }).start(),
      [scripture, isHeaderFixed]
    );

    const expand = React.useCallback(
      () =>
        Animated.timing(resizeAnim.current, {
          toValue: 80,
          ...getAnimationProps(false),
        }).start(),
      []
    );

    React.useEffect(() => {
      if (isCompact) {
        fadeOut();
        squeeze();
      } else {
        fadeIn();
        expand();
      }
    }, [isCompact, squeeze, expand, fadeIn, fadeOut]);

    const handleFixHeader = () => {
      fadeIn();
      expand();
      setIsHeaderFixed(true);
      onPress?.();
    };

    useFocusEffect(
      React.useCallback(() => {
        return () => {
          setIsHeaderFixed(false);
        };
      }, [])
    );

    const opacity = { opacity: fadeAnim.current };

    const backButtonText = isSession ? messages.session : messages.back;

    return (
      <>
        <Container style={[{ height: resizeAnim.current }]}>
          <Animated.View style={scripture ? [opacity] : []}>
            <TouchableOpacity onPress={selectFontSizeIncrementally}>
              <Icon name="text-fields" size={20} color={theme.colors.gray800} />
            </TouchableOpacity>
          </Animated.View>

          <Content>
            <Animated.View style={[opacity, StyleSheet.absoluteFill]}>
              <Background
                colors={[startColor, stopColor]}
                start={{ x: 0.1, y: 0.2 }}
              />
            </Animated.View>

            {book ? (
              <LabelBox>
                <Label onPress={openBooksModal}>
                  <LabelText isCompact={isCompact}>{book}</LabelText>
                </Label>
                <Label onPress={openVersionModal} isLast>
                  <LabelText isCompact={isCompact}>{version}</LabelText>
                </Label>
              </LabelBox>
            ) : null}
          </Content>
          <Animated.View style={{ opacity: 0 }}>
            <Icon name="cast" size={20} color={theme.colors.gray800} />
          </Animated.View>
        </Container>

        {showBackButton || scripture ? (
          <BottomBox>
            {showBackButton ? (
              <BackButton text={backButtonText} onPress={handleBackPress} />
            ) : null}

            {scripture ? (
              <MetaBox onPress={handleFixHeader}>
                <MetaText>{messages.selected}</MetaText>
                <MetaTextBold>{scripture}</MetaTextBold>
              </MetaBox>
            ) : null}
          </BottomBox>
        ) : null}
      </>
    );
  }
);
