import styled from "styled-components/native";

import { ResponsiveBox } from "~/components/responsive-box";
import { spacers } from "~/styles/theme";
import { hexToRGB } from "~/utils/colors";
import { mediaQuery } from "~/utils/media-query";

export const WebEmptyCard = styled.ImageBackground`
  width: 100%;
  background-color: ${({ theme }) => hexToRGB(theme.colors.white, 0.5)};
  border-radius: 20px;
  overflow: hidden;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray300};
  height: 420px;
`;

export const WebContent = styled.View`
  padding: ${spacers.ss8} ${spacers.ss6};
  max-width: 400px;
`;

export const ContentWrapper = styled(ResponsiveBox).attrs({
  mediaQueries: mediaQuery.tablet(`
    max-width: 310px;
  `),
})``;
