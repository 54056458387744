import React from "react";
import { FlatList } from "react-native";

import { GetPlanProgressesByUserIdData } from "~/state/content-progress";

import { ResourceEmpty } from "./empty";
import { getCardWidth } from "./utils";
import { ResourceItem } from "./item";
import { CardsWrapper } from "./styles";

const CARD_SPACING = 10;

interface Props {
  data: GetPlanProgressesByUserIdData[] | undefined;
}

export const ResourceCarousel = ({ data }: Props) => {
  if (!data?.length) {
    return (
      <CardsWrapper>
        <ResourceEmpty />
      </CardsWrapper>
    );
  }

  return (
    <FlatList
      data={data}
      horizontal
      keyExtractor={(item) => item.planProgressId}
      showsHorizontalScrollIndicator={false}
      snapToInterval={getCardWidth() + CARD_SPACING}
      decelerationRate="fast"
      contentContainerStyle={{ paddingHorizontal: CARD_SPACING }}
      renderItem={({ item }) => {
        if (!item.activeSession) {
          return null;
        }
        return (
          <ResourceItem
            {...item}
            activeSession={item.activeSession}
            size={data.length}
          />
        );
      }}
    />
  );
};
