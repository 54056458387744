export function chunkArray(array: any[], chunkSize: number): string[][] {
  const chunkedArray = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, index + chunkSize));
    index += chunkSize;
  }

  return chunkedArray;
}

/**
 * Shallow comparison for arrays of primitives
 */
export function isShallowPrimitiveArrayEqual(
  arr1: string[] | number[],
  arr2: string[] | number[]
) {
  return [...arr1].sort().join() === [...arr2].sort().join();
}

export const removeUndefinedKeys = <T>(obj: T): T => {
  const newObj: T = {};
  for (const key in obj) {
    if (obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export const parseRange = (input: string): number[] => {
  const [start, end] = input.split("-").map(Number);
  return end !== undefined
    ? Array.from({ length: end - start + 1 }, (_, i) => start + i)
    : [start];
};

export const getFixedItems = (array: any[], startIndex: number, count = 3) => {
  const maxStart = Math.max(0, array.length - count); // Ensure we don't go out of bounds
  const adjustedStart = Math.min(startIndex, maxStart); // Shift back if needed

  return array.slice(adjustedStart, adjustedStart + count);
};

export function findLastIndex<T>(
  arr: T[],
  predicate: (value: T, index: number, array: T[]) => boolean
): number {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i], i, arr)) {
      return i;
    }
  }
  return -1;
}
