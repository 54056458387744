import * as React from "react";
import { useRoute } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import type { SessionRouteProp } from "~/screens/session/types";
import {
  getLessonData,
  getLessonsBySessionId,
  getPersonalDevotionData,
  getSessionData,
  SectionOrder,
} from "~/state/flamelink";
import { ScrollViewFakePadding, ContentBox } from "./styles";
import { VolumeOverview } from "./components/volume-overview";
import { Day } from "./components/day";
import { SessionFooter } from "./components/footer";
import { Watch } from "./components/watch";
import { Consider } from "./components/consider";
import { Reflect } from "./components/reflect";
import { Engage } from "./components/engage";
import { ListenToGod } from "./components/listen-to-god";
import { Lesson } from "./components/lesson";
import { useAppSelector } from "~/state/hooks";
import { BOTTOM_SHEET_SNAP_POINTS } from "../content-container/constants";
import { isWeb } from "~/utils/platform";

interface ContentProps {
  currentDay: number;
  setCurrentDay: (value: React.SetStateAction<number>) => void;
  currentSection: number;
  setCurrentSection: (fn: (index: number) => number) => void;
  isBottomSheetFullHeight: boolean;
  lessonIndex: number;
}

type FooterProps = ContentProps & {
  sessionId: string;
  setLessonIndex: (index: number) => void;
  childSessionId: string;
};

export const ContentFooter = ({
  currentDay,
  setCurrentDay,
  currentSection,
  setCurrentSection,
  sessionId,
  lessonIndex,
  setLessonIndex,
  childSessionId,
}: FooterProps) => {
  const lessons = useAppSelector((state) =>
    getLessonsBySessionId(state, sessionId)
  );

  const data = useAppSelector((state) => getSessionData(state, sessionId));
  const lesson = useAppSelector((state) =>
    getLessonData(state, lessons?.[lessonIndex]?.id ?? "")
  );
  const previousLesson = useAppSelector((state) =>
    getLessonData(state, lessons?.[lessonIndex - 1]?.id ?? "")
  );
  const childSessionData = useAppSelector((state) =>
    getSessionData(state, childSessionId ?? "")
  );

  const daysLength = data?.days?.length ?? 0;
  const dayId = data?.days[currentDay === 0 ? 0 : currentDay - 1];

  const dayData = useAppSelector((state) =>
    getPersonalDevotionData(state, dayId ?? "")
  );

  const shouldRenderLessons = !!lesson;
  const shouldRenderDays = currentDay > 0;

  const sectionOrderLength = (() => {
    if (shouldRenderLessons) return lesson?.sectionOrder?.length ?? 0;

    if (childSessionId) return childSessionData?.sectionOrder?.length ?? 0;

    return data?.sectionOrder?.length ?? 0;
  })();

  const navigateToPreviousSection = React.useCallback(() => {
    if (currentSection === 0) return;

    setCurrentSection((val) => val - 1);
  }, [currentSection, setCurrentSection]);

  const navigateToPreviousDay = React.useCallback(() => {
    if (currentDay === 0) return;

    setCurrentDay((val) => val - 1);
  }, [currentDay, setCurrentDay]);

  const navigatePrevious = React.useCallback(
    (isSwiping?: boolean) => {
      shouldRenderDays
        ? !isSwiping && navigateToPreviousDay()
        : navigateToPreviousSection();
    },
    [navigateToPreviousDay, navigateToPreviousSection, shouldRenderDays]
  );

  const navigateToNextSection = React.useCallback(() => {
    if (
      shouldRenderLessons &&
      currentSection === lesson?.sectionOrder?.length - 1
    )
      return;

    if (sectionOrderLength && currentSection === sectionOrderLength - 1) return;

    setCurrentSection((val) => val + 1);
  }, [
    currentSection,
    lesson?.sectionOrder?.length,
    sectionOrderLength,
    setCurrentSection,
    shouldRenderLessons,
  ]);

  const navigateToNextDay = React.useCallback(() => {
    if (daysLength && currentDay === daysLength) return;

    setCurrentDay((val) => val + 1);
  }, [currentDay, daysLength, setCurrentDay]);

  const navigateNext = React.useCallback(
    (isSwiping?: boolean) => {
      shouldRenderDays
        ? !isSwiping && navigateToNextDay()
        : navigateToNextSection();
    },
    [shouldRenderDays, navigateToNextDay, navigateToNextSection]
  );

  const heading = (() => {
    if (shouldRenderLessons) {
      return lesson?.heading;
    }

    if (childSessionId) {
      return childSessionData?.volumeOverview?.heading;
    }

    return data?.volumeOverview?.heading;
  })();

  const previousLessionsSectionsCount =
    previousLesson?.sectionOrder?.length || 0;

  const isLoading = React.useMemo(() => {
    return (shouldRenderDays && !dayData) || (shouldRenderLessons && !lesson);
  }, [dayData, lesson, shouldRenderDays, shouldRenderLessons]);

  return (
    <SessionFooter
      navigatePrevious={navigatePrevious}
      navigateNext={navigateNext}
      daysLength={shouldRenderDays ? daysLength : sectionOrderLength}
      currentDay={shouldRenderDays ? currentDay : currentSection}
      shouldRenderDays={shouldRenderDays}
      heading={heading}
      shouldRenderLessons={shouldRenderLessons}
      lessonIndex={lessonIndex}
      lessonsLength={lessons?.length ?? 0}
      previousLessionsSectionsCount={previousLessionsSectionsCount}
      childSessionId={childSessionId}
      setLessonIndex={setLessonIndex}
      setCurrentSection={setCurrentSection}
      isLoading={isLoading}
    />
  );
};

export const Content: React.FC<ContentProps> = ({
  currentDay,
  currentSection,
  isBottomSheetFullHeight,
  lessonIndex,
}) => {
  const { bottom } = useSafeAreaInsets();
  const {
    params: { sessionId, lessonId, childSessionId },
  } = useRoute<SessionRouteProp>();
  const lessons = useAppSelector((state) =>
    getLessonsBySessionId(state, sessionId)
  );
  const data = useAppSelector((state) => getSessionData(state, sessionId));
  const lesson = useAppSelector((state) =>
    getLessonData(state, lessons?.[lessonIndex]?.id ?? "")
  );

  const childSessionData = useAppSelector((state) =>
    getSessionData(state, childSessionId ?? "")
  );

  const dayId = data?.days[currentDay === 0 ? 0 : currentDay - 1];

  const shouldRenderLessons = !!lesson;
  const shouldRenderDays = currentDay > 0;

  if (!data) return null;

  const renderSection = () => {
    // prioritize lesson rendering if there are lessons
    if (shouldRenderLessons) {
      const lessonSectionToRender =
        lesson?.sectionOrder?.[currentSection] ?? undefined;

      return (
        <Lesson lesson={lesson} lessonSectionToRender={lessonSectionToRender} />
      );
    }

    // if there are no lessons, render subsections either from the parent session or the child session
    const sectionToRender = childSessionId
      ? childSessionData?.sectionOrder?.[currentSection]
      : data?.sectionOrder?.[currentSection] ?? undefined;

    const renderData = childSessionId ? childSessionData : data;

    switch (sectionToRender) {
      case SectionOrder.VolumeOverview:
        return renderData?.volumeOverview ? (
          <VolumeOverview volumeOverview={renderData.volumeOverview} />
        ) : null;
      case SectionOrder.Watch:
        return renderData?.watch ? <Watch watch={renderData.watch} /> : null;
      case SectionOrder.Consider:
        return renderData?.consider ? (
          <Consider consider={renderData.consider} />
        ) : null;
      case SectionOrder.Reflect:
        return renderData?.reflect ? (
          <Reflect reflect={renderData.reflect} />
        ) : null;
      case SectionOrder.Engage:
        return renderData?.engage ? (
          <Engage engage={renderData.engage} />
        ) : null;
      case SectionOrder.ListenToGod:
        return renderData?.listenToGod ? (
          <ListenToGod listenToGod={renderData.listenToGod} />
        ) : null;
      default:
        return null;
    }
  };

  const bottomSheetHeight = isBottomSheetFullHeight
    ? BOTTOM_SHEET_SNAP_POINTS[1]
    : BOTTOM_SHEET_SNAP_POINTS[0];
  const heightOffset = isWeb ? 136 : 266;
  const minHeight = bottomSheetHeight - heightOffset;

  return (
    <>
      <ContentBox minHeight={minHeight}>
        {shouldRenderDays && dayId ? <Day dayId={dayId} /> : renderSection()}
      </ContentBox>

      {!isWeb ? <ScrollViewFakePadding extraMargin={bottom} /> : null}
    </>
  );
};
