import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { useAppDispatch, useAppSelector } from "~/state/hooks";
import {
  getBibleAbbreviation,
  getFullVerseOfTheDay,
} from "~/state/bible/selectors";
import { getVerseOfTheDay } from "~/state/bible/actions";
import { getIsDarkMode } from "~/state/settings/selectors";
import { navigateTo } from "~/utils/navigation";

import { Container, Content, Passage } from "./styles";
import * as routes from "~/constants/routes";

export const VerseOfTheDay = React.memo(() => {
  const data = useAppSelector(getFullVerseOfTheDay);
  const isDarkMode = useAppSelector(getIsDarkMode);
  const dispatch = useAppDispatch();

  const bibleVersion = useAppSelector(getBibleAbbreviation);

  const navigation = useNavigation<any>();

  React.useEffect(() => {
    dispatch(getVerseOfTheDay({}));
  }, [dispatch]);

  const onPress = React.useCallback(
    (formattedScripture?: string) => {
      navigateTo(navigation, routes.bibleTab, {
        screen: routes.bible,
        params: {
          scripture: formattedScripture,
          showBackButton: true,
        },
      });
    },
    [navigation]
  );

  if (!data) {
    return null;
  }

  return (
    <Container onPress={() => onPress(data?.scripture)}>
      {data?.text ? <Content>{data.text}</Content> : null}
      {data?.scripture ? (
        <Passage
          isDarkMode={isDarkMode}
        >{`${data.scripture} ${bibleVersion}`}</Passage>
      ) : null}
    </Container>
  );
});
