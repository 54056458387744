import * as React from "react";
import { useTheme } from "styled-components/native";

interface Props {
  style: any;
  gradientProps: {
    colors: string[];
  };
  children: React.ReactNode;
}

export const GradientBorderView = ({
  children,
  style,
  gradientProps,
}: Props) => {
  const colors = gradientProps.colors.join(", ");
  const background = `linear-gradient(to right, ${colors})`;
  const theme = useTheme();
  return (
    <div
      style={{
        padding: style.borderWidth,
        borderRadius: style.borderRadius,
        background,
      }}
    >
      <div
        style={{
          background: theme.colors.white,
          borderRadius: style.borderRadius - style.borderWidth,
        }}
      >
        {children}
      </div>
    </div>
  );
};
