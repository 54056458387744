import React from "react";
import { useNavigation } from "@react-navigation/native";
import { useTheme } from "styled-components/native";

import * as routes from "~/constants/routes";
import {
  CardContent,
  EmptyTitle,
  EmptyDescription,
  InlineButton,
  InlineButtonText,
} from "../styles";

import { messages } from "../intl";
import { WebEmptyCard, WebContent, ContentWrapper } from "./styles.web";

export const ResourceEmpty = () => {
  const navigation = useNavigation<any>();
  const theme = useTheme();

  const onPress = () => {
    navigation.navigate(routes.library);
  };

  return (
    <WebEmptyCard
      source={require("<assets>/resources/curriculum.png")}
      resizeMode="cover"
    >
      <ContentWrapper>
        <WebContent>
          <CardContent>
            <EmptyTitle>{messages.getStarted}</EmptyTitle>
            <EmptyDescription>{messages.emptyDescription}</EmptyDescription>
          </CardContent>
          <InlineButton onPress={onPress}>
            <InlineButtonText>{messages.emptyButton}</InlineButtonText>
          </InlineButton>
        </WebContent>
      </ContentWrapper>
    </WebEmptyCard>
  );
};
