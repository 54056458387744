import { getColorSchemeType, HeaderColorSchemes } from "~/utils/theme";
import { messages } from "./intl";

export function getGreeting(name: string): TextType {
  const colorSchemeType = getColorSchemeType();

  if (colorSchemeType === HeaderColorSchemes.Morning) {
    return { ...messages.greetingsMorningTitle, values: { name } };
  }

  if (colorSchemeType === HeaderColorSchemes.Afternoon) {
    return { ...messages.greetingsAfternoonTitle, values: { name } };
  }

  return { ...messages.greetingsEveningTitle, values: { name } };
}
