import React, { useRef, useState, useCallback } from "react";
import { Modal, LayoutChangeEvent, TouchableOpacity } from "react-native";
import {
  Camera as ExpoCamera,
  PermissionStatus,
  CameraType,
  CameraView,
  CameraMode,
} from "expo-camera";

import { formatMessage } from "~/utils/translation";
import { handleError } from "~/utils/logger";
import { Alert } from "~/components/alert";
import { isAndroid, isWeb } from "~/utils/platform";

import { ANDROID_DEFAULT_RATIO } from "./constants";
import {
  SafeArea,
  Container,
  ControlsContainer,
  Trigger,
  HeaderWrapper,
  CloseIcon,
  ReverseIcon,
  VideoTrigger,
  StopIcon,
} from "./styles";
import { getCameraRatio } from "./utils";
import { messages } from "./intl";

let resizeAttempts = 0;
const MAX_RESIZE_ATTEMPTS = 5;

const cameraTypes = {
  front: "front" as CameraType,
  back: "back" as CameraType,
};

type Props = {
  handlePicture: (picture: { uri: string }) => void;
  onClose: () => void;
  mode?: CameraMode;
  maxVideoDuration?: number;
};

export const Camera = React.memo<Props>(
  ({ handlePicture, onClose, mode, maxVideoDuration }) => {
    const cameraRef = useRef<CameraView>(null);

    const [unmountCamera, setUnmountCamera] = useState<boolean>(false);
    const [isCameraReady, setCameraReady] = useState<boolean>(false);
    const [cameraRatio, setCameraRatio] = useState<string>(
      ANDROID_DEFAULT_RATIO
    );
    const [cameraType, setCameraType] = useState<CameraType>(cameraTypes.back);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [isRecording, setIsRecording] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = useCallback(async () => {
      const { status: cameraStatus } =
        await ExpoCamera.requestCameraPermissionsAsync();

      if (cameraStatus === PermissionStatus.GRANTED) {
        setIsModalOpen(true);
        return;
      }

      Alert.alert(formatMessage(messages.picturePermissionAlert));
      onClose();
    }, [setIsModalOpen, onClose]);

    const closeModal = useCallback(() => {
      setIsModalOpen(false);
      onClose();
    }, [setIsModalOpen, onClose]);

    const toggleCameraType = React.useCallback(() => {
      setUnmountCamera(true);

      if (cameraType === cameraTypes.back) {
        setCameraType(cameraTypes.front);
      }

      if (cameraType === cameraTypes.front) {
        setCameraType(cameraTypes.back);
      }

      setTimeout(() => {
        setUnmountCamera(false);
      }, 200);
    }, [cameraType, setCameraType]);

    const takePicture = useCallback(async () => {
      const picture = await cameraRef?.current
        ?.takePictureAsync({ quality: 0.3 })
        .catch((e) => handleError(e));

      if (picture && handlePicture) {
        closeModal();
        handlePicture(picture);
      }
    }, [handlePicture, cameraRef, closeModal]);

    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const startWebRecording = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            facingMode:
              cameraType === cameraTypes.front ? "user" : "environment",
          },
          audio: true,
        });

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "video/webm",
        });

        mediaRecorderRef.current = mediaRecorder;
        let localChunks: BlobPart[] = [];

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            localChunks.push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          if (localChunks.length === 0) {
            console.error("No recorded chunks, recording failed.");
            return;
          }

          const videoBlob = new Blob(localChunks, { type: "video/webm" });
          const videoUrl = URL.createObjectURL(videoBlob);

          handlePicture({ uri: videoUrl });
          closeModal();

          // Stop all video tracks (important for cleanup)
          stream.getTracks().forEach((track) => track.stop());
        };

        mediaRecorder.start();
        setIsRecording(true);
      } catch (error) {
        console.error("Error starting web recording:", error);
      }
    };

    const stopWebRecording = () => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
      }
    };

    const recordVideo = async () => {
      if (isWeb) {
        if (isRecording) {
          stopWebRecording();
        } else {
          startWebRecording();
        }
        return;
      }

      if (!cameraRef.current) return;

      if (isRecording) {
        cameraRef.current?.stopRecording();
        setIsRecording(false);
      } else {
        setIsRecording(true);
        try {
          const video = await cameraRef.current?.recordAsync({
            maxDuration: maxVideoDuration,
          });

          if (video) {
            closeModal();
            handlePicture(video);
          }
        } catch (error) {
          console.error("Error recording video:", error);
        }
      }
    };

    const calculateRatio = useCallback(
      async (width: number, height: number) => {
        try {
          const ratio = await getCameraRatio(cameraRef.current, width, height);

          setCameraRatio(ratio);
        } catch (e) {
          if (resizeAttempts < MAX_RESIZE_ATTEMPTS) {
            setTimeout(() => {
              resizeAttempts++;

              calculateRatio(width, height);
            }, 200);
          }
          handleError(e);
        }
      },
      []
    );

    const onCameraReady = React.useCallback(() => {
      setCameraReady(true);
      if (isAndroid) {
        calculateRatio(dimensions.width, dimensions.height);
      }
    }, [setCameraReady, calculateRatio, dimensions.width, dimensions.height]);

    const onLayout = React.useCallback(
      async ({
        nativeEvent: {
          layout: { width, height },
        },
      }: LayoutChangeEvent) => {
        setDimensions({ width, height });
        calculateRatio(width, height);
      },
      [calculateRatio]
    );

    React.useEffect(() => {
      openModal();
    }, []);

    const videoProps =
      mode === "video"
        ? {
            videoQuality: "720p",
            videoCodec: "avc1",
            videoStabilization: "cinematic",
          }
        : {};

    const cameraFn = mode === "video" ? recordVideo : takePicture;

    return (
      <>
        <Modal animationType="fade" visible={isModalOpen}>
          <SafeArea>
            <Container onLayout={onLayout}>
              {unmountCamera || !isModalOpen ? null : (
                <CameraView
                  facing={cameraType}
                  style={{ flex: 1 }}
                  ref={cameraRef}
                  ratio={cameraRatio}
                  onCameraReady={onCameraReady}
                  useCamera2Api
                  onMountError={(e) => console.warn(e)}
                  mode={mode}
                  {...videoProps}
                />
              )}

              <HeaderWrapper>
                <TouchableOpacity onPress={closeModal}>
                  <CloseIcon />
                </TouchableOpacity>
                {!isWeb ? (
                  <TouchableOpacity
                    onPress={toggleCameraType}
                    disabled={unmountCamera}
                  >
                    <ReverseIcon />
                  </TouchableOpacity>
                ) : null}
              </HeaderWrapper>

              {isCameraReady ? (
                <ControlsContainer>
                  {mode === "video" ? (
                    <VideoTrigger isRecording={isRecording} onPress={cameraFn}>
                      {isRecording ? <StopIcon /> : null}
                    </VideoTrigger>
                  ) : (
                    <Trigger onPress={cameraFn} />
                  )}
                </ControlsContainer>
              ) : null}
            </Container>
          </SafeArea>
        </Modal>
      </>
    );
  }
);
