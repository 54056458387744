import { Timestamp } from "firebase/firestore";
import { Dayjs } from "dayjs";

type Action<T> = {
  type: string;
  payload: T;
  meta: any;
  error: any;
};

export type Stats = {
  totalChurches: number;
  totalGroups: number;
  totalProfiles: number;
  usUsers: number;
  kidminUsers: number;
  totalUsers: number;
  usChurches: number;
  agChurches: number;
  usersPerGroup: number;
  iosDownloads: number;
  androidDownloads: number;
  date?: Timestamp;
  type: string;
  activeUsers: number;
  sessions: number;
  userEngagementDuration: number;
  engagedSessions: number;
  gaTotalUsers: number;
  newUsers: number;
  impact?: string;
  progress?: ProgressStats[];
};

export type DateRange = {
  from: Dayjs;
  to: Dayjs;
};

export type ProgressStats = {
  numUsersCompleted: number;
  numUsersCompletedSome: number;
  numUsersStarted: number;
  planId: string;
};

export type StatsState = {
  statsHistory: Stats[];
  churches: ChurchStats[];
  dateRange: DateRange;
  isLoading: boolean;
  currentStats: Stats | null;
  currentStatsFetched: string | null;
};

export type AppSessionData = {
  engagedPerUser: number;
  avgEngagementTime: string;
  dailyEngagementTime: Array<{
    label: string;
    value: number;
  }>;
};

export type StatsData = {
  label: string;
  value: number;
};

export enum ChurchType {
  All,
  US,
  AG,
}

export type ChurchStats = {
  churchName: string;
  churchCity: string;
  churchState?: string;
  churchZip?: string;
  churchCountry?: string;
  isAgChurch?: boolean;
  count: number;
};

type Callbacks = {
  onSuccess: () => void;
  onError: (e?: Error) => void;
};

export type GetStatsHistoryPayload = {
  dateRange?: DateRange;
};

export type GetStatsHistoryAction = Action<GetStatsHistoryPayload>;

export type GetCurrentStatsPayload = Callbacks;

export type GetCurrentStatsAction = Action<GetCurrentStatsPayload>;

export type getChurchesDataPayload = Callbacks;

export type getChurchesDataAction = Action<getChurchesDataPayload>;
