import * as React from "react";
import { GestureResponderEvent } from "react-native";

import { formatMessage } from "~/utils/translation";
import {
  pickAndProcessImage,
  pickAndProcessVideo,
} from "~/components/camera/utils";
import { useActionSheet } from "~/components/action-sheet";
import { PickerResourceType } from "~/components/camera/types";

import { messages } from "./intl";

const IMAGE_SIZE = {
  width: 500,
  height: 500,
};

type Size = {
  width: number;
  height: number;
};

export type ImageProps = {
  uri: string;
};

interface Props {
  handleResource: (arg0: ImageProps) => void;
  size?: Size;
  type?: PickerResourceType;
  maxVideoDuration?: number;
}

export const useCamera = ({
  handleResource,
  type = PickerResourceType.IMAGE,
  size = IMAGE_SIZE,
  maxVideoDuration = 60,
}: Props) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const { showActionSheetWithOptions } = useActionSheet();

  const buttonMessage =
    type === PickerResourceType.VIDEO
      ? messages.buttonVideo
      : messages.buttonPhoto;

  const translatedOptions = [
    buttonMessage,
    messages.buttonLibrary,
    messages.buttonCancel,
  ].map((message) => formatMessage(message));

  const handleOpenLibrary = React.useCallback(async () => {
    if (type === PickerResourceType.VIDEO) {
      const mediaFile = await pickAndProcessVideo({
        maxDuration: maxVideoDuration,
      });
      if (!mediaFile) {
        return;
      }

      handleResource({ uri: mediaFile.uri });
    } else {
      const mediaFile = await pickAndProcessImage({ size });
      if (!mediaFile) {
        return;
      }

      handleResource({ uri: mediaFile.uri });
    }
  }, [handleResource, size, type, maxVideoDuration]);

  const handleCloseModal = React.useCallback(
    () => setIsModalVisible(false),
    [setIsModalVisible]
  );

  const onShowOptions = (event: GestureResponderEvent) => {
    showActionSheetWithOptions(
      {
        options: translatedOptions,
        cancelButtonIndex: 2,
      },
      (selectedIndex?: number) => {
        switch (selectedIndex) {
          case 0:
            return setIsModalVisible(true);
          case 1:
            return handleOpenLibrary();
          default:
            return;
        }
      },
      // @ts-ignore
      event
    );
  };

  return {
    onShowOptions,
    isModalVisible,
    handleCloseModal,
  };
};
