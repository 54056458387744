function stableSort(array: Array<any>, comparator: (a: any, b: any) => number) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a: any, b: any, orderBy: string) {
  const aValue = a[orderBy];
  const bValue = b[orderBy];
  const isAValueString = typeof aValue === "string";
  const isBValueString = typeof bValue === "string";
  const aValueClean = isAValueString ? aValue.trim() : aValue;
  const bValueClean = isBValueString ? bValue.trim() : bValue;

  const isFalsy = (value: any) => !value && value !== 0;

  if (
    isAValueString &&
    isBValueString &&
    (isFalsy(aValueClean) || isFalsy(bValueClean))
  )
    return 0;

  if (bValueClean < aValueClean) return -1;
  if (bValueClean > aValueClean) return 1;
  return 0;
}

function getComparator(order: string, orderBy: string) {
  if (order === "desc")
    return (a: any, b: any) => descendingComparator(a, b, orderBy);
  else return (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export { stableSort, getComparator, descendingComparator };
