export const messages = {
  getStarted: {
    id: "resource-card.empty.title",
    defaultMessage: "Get Started",
  },
  emptyDescription: {
    id: "resource-card.empty.description",
    defaultMessage:
      "Explore our curriculum library to find a resource that works for your church! Each curriculum in the library works together and builds on each other, providing you with an intentional discipleship strategy for all ages.",
  },
  emptyButton: {
    id: "resource-card.empty.button",
    defaultMessage: "Add your first curriculum",
  },
  volumeTitle: {
    id: "resource-card.volume-title",
    defaultMessage: "Volume {{index}}: {{title}}",
  },
  devotionDayTitle: {
    id: "resource-card.devo-title",
    defaultMessage: "Devotion {{index}}: {{title}}",
  },
  downloads: {
    id: "resource-card.downloads",
    defaultMessage: "Downloads",
  },
  more: {
    id: "resource-card.more",
    defaultMessage: "More",
  },
};
