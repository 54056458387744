import { css } from "styled-components";

export const mediaQuery = {
  tablet: (styles: string) => css`
    @media (max-width: 759px) {
      ${styles}
    }
  `,
  mobile: (styles: string) => css`
    @media (max-width: 399px) {
      ${styles}
    }
  `,
};
