import React from "react";
import { Greetings } from "~/components/greetings";
import { HeaderBar } from "~/components/header-bar";

import { WebHeaderContainer } from "./styles";

export function HomeHeader() {
  return (
    <WebHeaderContainer>
      <HeaderBar withBackButton={false} isSticky />

      <Greetings />
    </WebHeaderContainer>
  );
}
