import styled, { css, Theme } from "styled-components/native";

import { Text2, Text3 } from "~/components/text";
import { colors, fontSizes, spacers } from "~/styles/theme";

import { ButtonTypes } from "./types";
import { getWebContainerStyle } from "~/utils/web";

type Props = {
  type: ButtonTypes;
  disabled: boolean;
  stretch?: boolean;
  pressed?: boolean;
  hasIcon?: boolean;
  isUppercase?: boolean;
  isLast?: boolean;
};

const backgroundColorMap = (theme: Theme) =>
  new Map([
    [ButtonTypes.Primary, theme.colors.gray700],
    [ButtonTypes.Secondary, theme.colors.transparent],
    [ButtonTypes.Outlined, theme.colors.transparent],
    [ButtonTypes.Apple, theme.colors.white],
    [ButtonTypes.Google, theme.colors.white],
  ]);

const labelColorMap = (theme: Theme) =>
  new Map([
    [ButtonTypes.Primary, theme.colors.white],
    [ButtonTypes.Secondary, theme.colors.gray800],
    [ButtonTypes.Outlined, theme.colors.gray800],
    [ButtonTypes.Apple, theme.colors.black],
    [ButtonTypes.Google, theme.colors.black],
  ]);

const borderColorMap = (theme: Theme) =>
  new Map([
    [ButtonTypes.Primary, theme.colors.transparent],
    [ButtonTypes.Secondary, theme.colors.transparent],
    [ButtonTypes.Outlined, theme.colors.gray700],
    [ButtonTypes.Apple, theme.colors.gray100],
    [ButtonTypes.Google, theme.colors.gray100],
  ]);

export const pressedStyle = { opacity: 0.9 };

export const PressableWrapper = styled.Pressable<Props>`
  flex-direction: row;
  background-color: ${({ type, theme }) => backgroundColorMap(theme).get(type)};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  border: 1px solid ${({ type, theme }) => borderColorMap(theme).get(type)};
  border-radius: 26px;
  min-height: ${({ hasIcon }) => (hasIcon ? 56 : 52)}px;
  justify-content: center;
  align-items: center;
  padding-horizontal: ${spacers.ss6};
  ${({ stretch }) => (stretch ? "flex: 1;" : "")}
  ${({ isLast }) => (isLast ? "top: -12px" : "")}
  ${getWebContainerStyle(340)}
`;

const labelWithIcon = css`
  font-family: SFProDisplayMedium;
  font-size: ${fontSizes.fs2};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Label = styled(Text3)<Props>`
  color: ${({ type, theme }) => labelColorMap(theme).get(type)};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  ${({ hasIcon }) => (hasIcon ? labelWithIcon : "")};
  letter-spacing: ${({ isUppercase }) => (isUppercase ? 1.3 : 1)}px;
  font-size: ${({ isUppercase }) => (isUppercase ? 14 : 15)}px;
  font-family: SFProDisplayMedium;
`;

export const IconBox = styled.View`
  padding-left: ${spacers.ss3};
`;

export const LeftIconBox = styled.View`
  padding-right: ${spacers.ss5};
  position: absolute;
  left: ${spacers.ss6};
  top: 14px;
`;

export const LoadingBox = styled.View`
  position: absolute;
  top: 14px;
  right: 20px;
`;

export const ListButtonWrapper = styled.Pressable`
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.gray100};
  border-radius: 12px;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: ${spacers.ss6};
  margin-bottom: ${spacers.ss4};
`;

export const ListButtonLabel = styled(Text2)`
  color: ${({ theme }) => theme.colors.gray500};
`;

export const InlineWrapper = styled.Pressable`
  justify-content: center;
  padding: ${spacers.ss4};
`;

export const InlineText = styled(Text3)<{
  isUppercase?: boolean;
  disabled?: boolean;
}>`
  font-family: SFProDisplayMedium;
  letter-spacing: 1px;
  color: ${({ theme }) => theme.colors.gray900};
  ${({ isUppercase }) => (isUppercase ? "text-transform: uppercase;" : "")}
  ${({ disabled }) => (disabled ? "opacity: 0.7" : "")}
`;
