import styled from "styled-components/native";
import { ImageBackground as ExpoImageBackground } from "expo-image";
import { spacers } from "~/styles/theme";
import { isWeb } from "~/utils/platform";
import { getWebContainerStyle } from "~/utils/web";
import { Text3 } from "~/components/text";
import { HEADER_BAR_HEIGHT } from "~/constants";

export const Container = styled.View`
  ${getWebContainerStyle(1024)};
  ${isWeb ? `padding-top: ${HEADER_BAR_HEIGHT}px;` : ""};
`;

export const CardContainer = styled.View`
  flex-direction: ${isWeb ? "row" : "column"};
`;

export const HighlightedBox = styled.View`
  flex: 1;
`;

export const HighlightedContainer = styled.View`
  margin-top: ${spacers.ss6};
  ${isWeb && "display: block;"};
`;

export const ImageBackground = styled(ExpoImageBackground)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const Section = styled.View`
  margin-bottom: ${spacers.ss7};
  flex-direction: row;
`;

export const SectionTitle = styled(Text3)`
  font-family: MontserratSemiBold;
  margin-top: ${spacers.ss7};
  margin-bottom: ${spacers.ss4};
  padding-horizontal: ${spacers.ss8};
  font-size: 16px;
`;

export const ResourcesTitle = styled(Text3)`
  font-family: MontserratSemiBold;
  margin-bottom: ${spacers.ss5};
  padding-horizontal: ${spacers.ss6};
  font-size: 16px;
  height: 24px;
`;
