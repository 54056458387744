import { call, select, put, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  query,
  collection,
  getDocs,
  where,
  DocumentSnapshot,
  Timestamp,
  orderBy,
  limit,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { database, functions } from "<config>/firebase";
import NetInfo from "@react-native-community/netinfo";
import dayjs from "dayjs";

import { getStatsCollection } from "~/constants/collections";
import { getEnvironment, getUserId } from "~/state/user/selectors";
import { handleError } from "~/utils/logger";

import { getStatsHistory, getChurchesData, getRecentStats } from "./actions";
import {
  setStatsHistory,
  setChurchData,
  setIsLoading,
  setCurrentStats,
} from "./slice";
import { getChurchesDataAction, GetStatsHistoryAction, Stats } from "./types";
import { getCurrentDataFetched, getDateRange } from "./selectors";

export function* getStatsHistoryFn({
  payload: { dateRange: defaultDateRange },
}: GetStatsHistoryAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    yield put(setIsLoading(false));

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    yield put(setIsLoading(true));

    const env = yield select(getEnvironment);
    const collectionName = getStatsCollection(env);
    const dateRange = yield select(getDateRange);
    const customRange = defaultDateRange || dateRange;
    const statsSnapshots = yield call(
      // @ts-ignore
      getDocs,
      query(
        collection(database, collectionName),
        where("date", ">=", Timestamp.fromDate(new Date(customRange.from))),
        where("date", "<=", Timestamp.fromDate(new Date(customRange.to)))
      )
    );

    const data = statsSnapshots?.docs?.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as Stats[];

    if (data) {
      yield put(setStatsHistory(data));
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

export const getStatsHistorySaga = function* () {
  yield takeLatest(getStatsHistory.type, getStatsHistoryFn);
};

export function* getChurchesDataFn({
  payload: { onSuccess, onError },
}: getChurchesDataAction): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    if (onSuccess) yield call(onSuccess);

    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }
  try {
    const getData = httpsCallable(functions, "reports-getchurches");
    const response = yield call(getData, { userId });

    if (response?.data?.success) {
      const { data } = response.data;
      yield put(setChurchData(data));
      yield call(onSuccess);
    } else {
      throw new Error();
    }
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export const getChurchesDataSaga = function* () {
  yield takeLatest(getChurchesData.type, getChurchesDataFn);
};

export function* getRecentStatsFn(): SagaIterator {
  const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> = yield call(
    NetInfo.fetch
  );

  if (!isConnected) {
    return;
  }

  const userId = yield select(getUserId);
  if (!userId) {
    return;
  }

  const today = dayjs().format("DD/MM/YYYY");
  const lastFetched = yield select(getCurrentDataFetched);

  if (today === lastFetched) {
    return;
  }

  try {
    const collectionName = getStatsCollection();
    const snapshot = yield call(
      // @ts-ignore
      getDocs,
      query(
        collection(database, collectionName),
        orderBy("date", "desc"),
        limit(1)
      )
    );

    const data = snapshot?.docs?.map((doc: DocumentSnapshot) =>
      doc.data()
    ) as Stats[];

    if (Array.isArray(data) && data.length) {
      yield put(setCurrentStats(data[0]));
    } else {
      throw new Error("No data available");
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

export const getRecentStatsSaga = function* () {
  yield takeLatest(getRecentStats.type, getRecentStatsFn);
};
