import React from "react";
import { Keyboard, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useTheme } from "styled-components/native";

import { SettingsStackParamList } from "~/navigation/settings-stack";
import { SettingsScreen } from "~/components/settings";
import { ModalHeader } from "~/components/modal-header";
import * as routes from "~/constants/routes";
import { formatMessage } from "~/utils/translation";
import { useAppDispatch } from "~/state/hooks";
import { submitUserStory } from "~/state/app-feedback";
import { Button } from "~/components/button";
import { useKeyboard } from "~/utils/keyboard";
import { isWeb } from "~/utils/platform";
import { useAlerts } from "~/state/alerts";

import { VideoUpload } from "./video-upload";
import { messages } from "./intl";
import {
  Description,
  Title,
  SubmitButtonContainer,
  Content,
  StoryInput,
  UploadContainer,
} from "./styles";
import { colors } from "~/styles/theme";

const PADDING_BOTTOM_OFFSET = 20;

export type ShareYourStoryProps = NativeStackScreenProps<
  SettingsStackParamList,
  typeof routes.shareYourStory
>;

export const ShareYourStory = () => {
  const navigation = useNavigation<ShareYourStoryProps["navigation"]>();
  const dispatch = useAppDispatch();
  const { keyboardHeight } = useKeyboard();
  const scrollViewRef = React.useRef<ScrollView>(null);
  const theme = useTheme();

  const [text, setText] = React.useState<string>("");
  const [videoUri, setVideoUri] = React.useState<string>("");
  const [thumbnailUri, setThumbnailUri] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);

  const { pushAlert } = useAlerts();

  const onDonePress = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  const onSubmit = React.useCallback(() => {
    if (isLoading) return;

    setIsLoading(true);

    const onComplete = () => {
      setIsLoading(false);
      setText("");
      setVideoUri("");
      setThumbnailUri("");
      setUploadProgress(0);
      if (!isWeb) {
        navigation.goBack();
      }
    };

    const data = {
      text,
      videoUri,
      thumbnailUri,
      onProgress: (progress: number) => {
        setUploadProgress(progress);
      },
      onSuccess: () => {
        pushAlert({
          message: messages.thankYou,
          color: colors.emerald600,
        });
        onComplete();
      },
      onError: (error?: string) => {
        if (error?.includes("Video duration")) {
          pushAlert({
            message: messages.videoTooLong,
            color: colors.red600,
          });
        }
        onComplete();
      },
    };

    dispatch(submitUserStory(data));
  }, [
    dispatch,
    text,
    videoUri,
    thumbnailUri,
    isLoading,
    navigation,
    pushAlert,
  ]);

  const onSubmitEditting = () => Keyboard.dismiss();

  const isFormDisabled = React.useMemo(() => {
    return text.length === 0;
  }, [text.length]);

  React.useEffect(() => {
    if (keyboardHeight > 0) {
      setTimeout(() => {
        scrollViewRef?.current?.scrollToEnd();
      }, 0);
    }
  }, [keyboardHeight]);

  return (
    <SettingsScreen
      screenName="share-your-story"
      paddingBottom={keyboardHeight - PADDING_BOTTOM_OFFSET}
    >
      {!isWeb ? (
        <ModalHeader onLeftPress={onDonePress} onRightPress={onDonePress} />
      ) : null}

      <ScrollView
        ref={scrollViewRef}
        onContentSizeChange={() => {
          scrollViewRef?.current?.scrollToEnd();
        }}
      >
        <Title>{messages.subtitle}</Title>

        <Description>{messages.description}</Description>

        <Content>
          <StoryInput
            onChangeText={setText}
            onSubmitEditing={onSubmitEditting}
            returnKeyType="done"
            value={text}
            selectionColor={theme.colors.indigo400}
            label={formatMessage(messages.label)}
          />

          <UploadContainer>
            <VideoUpload
              isLoading={isLoading}
              videoUri={videoUri}
              onChange={setVideoUri}
              onThumbnailChange={setThumbnailUri}
              uploadProgress={uploadProgress}
            />
          </UploadContainer>

          <SubmitButtonContainer>
            <Button
              onPress={onSubmit}
              isDisabled={isFormDisabled || isLoading}
              text={formatMessage(messages.submit)}
              isLoading={isLoading}
            />
          </SubmitButtonContainer>
        </Content>
      </ScrollView>
    </SettingsScreen>
  );
};
