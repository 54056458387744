import * as React from "react";
import { ScrollView } from "react-native";
import { requestTrackingPermissionsAsync } from "~/utils/expo-tracking-transparency";
import { Settings } from "react-native-fbsdk-next";
import {
  useIsFocused,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { ResourceCarousel } from "~/components/resources-new";
import { useAlert } from "~/components/alert";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getShouldAskForFeedback } from "~/state/user/selectors";
import * as routes from "~/constants/routes";
import { PostAuthParamList } from "~/navigation/post-auth";
import { genericMessages } from "~/constants/intl";
import { formatMessage } from "~/utils/translation";
import { getHomeResourcesProgress } from "~/state/content-progress/selectors";

import { HomeHeader } from "./home-header";
import { GlobalImpact } from "./global-impact";
import { messages } from "./intl";
import {
  dismissAskForFeedback,
  openAskForFeedback,
} from "~/state/app-feedback";
import { getTutorialStep } from "~/state/tutorial/selectors";
import { useTutorial } from "~/state/tutorial";
import { getIsDarkMode } from "~/state/settings";
import { VerseOfTheDay } from "~/components/verse-of-the-day";
import { getHomeData } from "~/state/flamelink/selectors";
import { HomeCard } from "~/components/home-card";
import { isWeb } from "~/utils/platform";
import { TAB_BAR_HEIGHT } from "~/components/tab-bar/styles";
import { getBackgroundImage } from "~/constants";

import {
  Container,
  CardContainer,
  ImageBackground,
  SectionTitle,
  Section,
} from "./styles";

type NavigationProp = NativeStackScreenProps<
  PostAuthParamList,
  "settings.modal"
>;

export const Home = () => {
  const dispatch = useAppDispatch();
  const shouldAskForFeedback = useAppSelector(getShouldAskForFeedback);
  const tutorialStep = useAppSelector((state) =>
    getTutorialStep(state, "home")
  );
  const isDarkMode = useAppSelector(getIsDarkMode);
  const homeData = useAppSelector(getHomeData);
  const { data: planProgressesData } = useAppSelector(getHomeResourcesProgress);

  const navigation = useNavigation<NavigationProp["navigation"]>();
  const { showAlert } = useAlert();
  const route = useRoute();
  const isFocused = useIsFocused();
  const { showTutorial } = useTutorial(route.name, isFocused);

  const onModalPress = (uri: string) => {
    window.open(uri, "_blank", "noopener,noreferrer");
  };

  React.useEffect(() => {
    (async () => {
      if (isWeb) {
        return;
      }

      const { status } = await requestTrackingPermissionsAsync();
      if (status === "granted") {
        Settings.setAdvertiserTrackingEnabled(true);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (shouldAskForFeedback) {
      setTimeout(
        () =>
          showAlert(formatMessage(messages.appFeedback), "", [
            {
              text: formatMessage(genericMessages.yes),
              onPress: () => {
                dispatch(openAskForFeedback());
                navigation.navigate(routes.settingsModal, {
                  screen: routes.appFeedback,
                });
              },
            },
            {
              text: formatMessage(genericMessages.no),
              onPress: () => {
                dispatch(dismissAskForFeedback());
              },
            },
          ]),
        3000
      );
    }
  }, [dispatch, navigation, shouldAskForFeedback, showAlert]);

  React.useEffect(() => {
    showTutorial(tutorialStep);
  }, [showTutorial, tutorialStep]);

  const image = getBackgroundImage(isDarkMode);
  const isEmpty = !planProgressesData?.length;

  const { featuredCard, regularData } = homeData.reduce(
    (acc, item) => {
      if (item.isFeatured) {
        acc.featuredCard = item;
      } else {
        acc.regularData.push(item);
      }
      return acc;
    },
    { featuredCard: null, regularData: [] } as {
      featuredCard: (typeof homeData)[0] | null;
      regularData: typeof homeData;
    }
  );

  return (
    <ImageBackground source={image}>
      <ScrollView
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
        showsVerticalScrollIndicator={false}
        hasFixedHeader
        isDarkMode={isDarkMode}
        contentContainerStyle={{ paddingBottom: TAB_BAR_HEIGHT + 60 }}
      >
        <Container>
          <HomeHeader />

          <SectionTitle>{messages.myResources}</SectionTitle>

          <ResourceCarousel data={planProgressesData} />

          <VerseOfTheDay />

          {!isEmpty ? (
            <Section>
              <GlobalImpact />
              {featuredCard ? (
                <HomeCard
                  {...featuredCard}
                  key="featured-card"
                  onModalPress={onModalPress}
                />
              ) : null}
            </Section>
          ) : null}

          <CardContainer>
            {regularData.map((item) => (
              <HomeCard {...item} key={item.id} onModalPress={onModalPress} />
            ))}
          </CardContainer>
        </Container>
      </ScrollView>
    </ImageBackground>
  );
};
