export const messages = {
  title: {
    id: "global-impact.title",
    defaultMessage: "Global Impact",
  },
  description: {
    id: "global-impact.description",
    defaultMessage: "people discipled",
  },
  text: {
    id: "global-impact.text",
    defaultMessage: "We have a vision to engage every church in God's Word.",
  },
};
