import * as React from "react";
import { Animated } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import * as routes from "~/constants/routes";
import { Screen } from "~/components/screen";
import { HeaderBar } from "~/components/header-bar";
import {
  getLessonsBySessionId,
  getSessionData,
  loadLessonsBySessionId,
  loadSession,
} from "~/state/flamelink";
import { Loader } from "~/components/loader";
import { BackButton } from "~/components/back-button";
import { BackgroundGradient } from "~/components/background-gradient";
import type { HomeProps } from "~/components/day-bubbles";
import { useAppDispatch, useAppSelector } from "~/state/hooks";
import { getPlanThumbnail } from "~/state/flamelink/selectors";

import { ContentContainer } from "./components/content-container";
import { ContentFooter } from "./components/content";
import { Content } from "./components/content";
import { LandingContent } from "./components/landing-content";
import type { SessionProps } from "./types";
import { getSessionNotes } from "~/state/session-notes/actions";
import { navigateTo } from "~/utils/navigation";
import { ScrollViewFakePadding } from "./components/content/styles";
import { isWeb } from "~/utils/platform";

import { FooterBox, BackButtonWrapper } from "./styles";
import {
  getProgressById,
  ProgressState,
  useUpdateSessionProgressMutation,
} from "~/state/content-progress";

export const Session: React.FC<SessionProps> = ({ route }) => {
  const {
    sessionId,
    planId,
    lessonId,
    childSessionId,
    dayIndex,
    dayKey,
    backToTOC,
    currentSection: defaultCurrentSection = 0,
    sessionProgressId = "",
  } = route.params;
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => getSessionData(state, sessionId));
  const [lessonIndex, setLessonIndex] = React.useState<number>(0);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = React.useState(false);
  const [isBottomSheetFullHeight, setIsBottomSheetFullHeight] =
    React.useState(false);
  const [currentDay, setCurrentDay] = React.useState(dayIndex);
  const [currentSection, setCurrentSection] = React.useState<number>(
    Number(defaultCurrentSection)
  );
  const navigation = useNavigation<HomeProps["navigation"]>();
  const opacity = React.useRef(new Animated.Value(0)).current;

  const [updateSessionProgress] = useUpdateSessionProgressMutation();

  const progressData = useAppSelector((state) =>
    getProgressById(state, sessionProgressId)
  );

  const lessons = useAppSelector((state) =>
    getLessonsBySessionId(state, sessionId)
  );

  const bottom = useSafeAreaInsets().bottom;

  const planThumbnail = useAppSelector((state) =>
    getPlanThumbnail(state, planId)
  );

  React.useEffect(() => {
    setCurrentDay(dayIndex);

    // Reset the current section when we navigate to a new day or session
    setCurrentSection(Number(defaultCurrentSection));

    dispatch(loadSession(sessionId));

    dispatch(
      getSessionNotes({
        sessionId,
      })
    );

    if (lessonId) {
      // we load all lessons at once to have them available between navigation inside the session bottom sheet
      dispatch(loadLessonsBySessionId(sessionId));
    }

    if (childSessionId) {
      dispatch(loadSession(childSessionId));
    }
  }, [
    dayIndex,
    sessionId,
    lessonId,
    dispatch,
    childSessionId,
    defaultCurrentSection,
    lessonIndex,
  ]);

  React.useEffect(() => {
    if (isWeb) {
      return;
    }
    const startAnimation = () => {
      Animated.timing(opacity, {
        toValue: 1,
        duration: 500,
        useNativeDriver: true,
      }).start();
    };

    const delayTimeout = setTimeout(startAnimation, 3500);

    return () => clearTimeout(delayTimeout);
  }, [opacity]);

  React.useEffect(() => {
    const initialLessonIndex = lessonId
      ? lessons?.findIndex((_lesson) => _lesson.id === lessonId)
      : 0;

    setLessonIndex(initialLessonIndex ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonId]);

  const navigateToToC = () => {
    navigateTo(navigation, routes.libraryTab, {
      screen: routes.plan,
      params: { planId, backToHome: true },
    });
  };

  // If we come to the session screen from the home tab
  // we need to make sure we navigate back to the table of contents
  const headerProps = backToTOC
    ? {
        onBackPress: navigateToToC,
      }
    : {};

  const onBackButton = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigateToToC();
  };

  const handleCurrentSection = React.useCallback(
    async (fn: (index: number) => number) => {
      const newCurrentSection = fn(Number(currentSection));
      setCurrentSection(newCurrentSection);
      if (
        !sessionProgressId ||
        progressData?.state !== ProgressState.InProgress
      ) {
        return;
      }
      await updateSessionProgress({
        dayKey,
        currentSection: newCurrentSection,
        sessionProgressId,
      });
    },
    [
      updateSessionProgress,
      sessionProgressId,
      currentSection,
      progressData,
      dayKey,
    ]
  );

  if (!data) return <Loader fullScreen />;

  return (
    <Screen screenName="session" isFragment>
      <StatusBar style="light" />
      <HeaderBar withLightButtons {...headerProps} />

      {isWeb && (
        <BackButtonWrapper>
          <BackButton hasText lightBackButton onPress={onBackButton} />
        </BackButtonWrapper>
      )}

      {planThumbnail && <BackgroundGradient source={{ uri: planThumbnail }} />}

      <LandingContent currentDay={currentDay} />

      <ContentContainer
        isBottomSheetOpen={isBottomSheetOpen}
        setIsBottomSheetOpen={setIsBottomSheetOpen}
        isBottomSheetFullHeight={isBottomSheetFullHeight}
        setIsBottomSheetFullHeight={setIsBottomSheetFullHeight}
        currentSection={currentSection}
      >
        <>
          <Content
            currentDay={currentDay}
            setCurrentDay={setCurrentDay}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            isBottomSheetFullHeight={isBottomSheetFullHeight}
            lessonIndex={lessonIndex}
          />
          {!isWeb ? (
            <ScrollViewFakePadding extraMargin={50} />
          ) : (
            <ContentFooter
              currentDay={currentDay}
              setCurrentDay={setCurrentDay}
              currentSection={currentSection}
              setCurrentSection={handleCurrentSection}
              isBottomSheetFullHeight={isBottomSheetFullHeight}
              sessionId={sessionId || ""}
              lessonIndex={lessonIndex}
              setLessonIndex={setLessonIndex}
              childSessionId={childSessionId || ""}
            />
          )}
        </>
      </ContentContainer>

      {!isWeb ? (
        <FooterBox
          style={{
            opacity,
          }}
          bottom={bottom}
        >
          <ContentFooter
            currentDay={currentDay}
            setCurrentDay={setCurrentDay}
            currentSection={currentSection}
            setCurrentSection={handleCurrentSection}
            isBottomSheetFullHeight={isBottomSheetFullHeight}
            sessionId={sessionId || ""}
            lessonIndex={lessonIndex}
            setLessonIndex={setLessonIndex}
            childSessionId={childSessionId || ""}
          />
        </FooterBox>
      ) : null}
    </Screen>
  );
};
