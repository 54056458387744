import React from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import { useAppSelector } from "~/state/hooks";
import { getUserFirstName } from "~/state/user/selectors";
import { HeaderIcons } from "~/components/header-bar";
import { HEADER_BAR_TOP_OFFSET } from "~/constants";
import { isWeb } from "~/utils/platform";

import { GreetingsContainer, GreetingsTitle, TitleBox } from "./styles";
import { getGreeting } from "./utils";

export function Greetings() {
  const greetingsName = useAppSelector(getUserFirstName);
  const greeting = getGreeting(greetingsName);
  const { top } = useSafeAreaInsets();

  const topMargin = top + HEADER_BAR_TOP_OFFSET;

  return (
    <GreetingsContainer topMargin={topMargin}>
      <TitleBox>
        <GreetingsTitle>{greeting}</GreetingsTitle>
      </TitleBox>

      {!isWeb ? <HeaderIcons /> : null}
    </GreetingsContainer>
  );
}
