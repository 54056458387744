import * as React from "react";
import styled, { FlattenSimpleInterpolation } from "styled-components";

export const StyledBox = styled.div<{
  mediaQueries?: FlattenSimpleInterpolation;
}>`
  ${({ mediaQueries = "" }) => mediaQueries}
`;

interface Props {
  children: React.ReactNode;
  mediaQueries?: FlattenSimpleInterpolation;
}

export const ResponsiveBox = ({ children, mediaQueries }: Props) => {
  return <StyledBox mediaQueries={mediaQueries}>{children}</StyledBox>;
};
