import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import { ModalHeader } from "~/components/modal-header";
import { Screen } from "~/components/screen";
import { useAppDispatch, useAppNetInfo, useAppSelector } from "~/state/hooks";
import { asyncLogEvent, events } from "~/utils/analytics";
import {
  getCurrentBible,
  getBibles,
  getBibleLanguages,
} from "~/state/bible/selectors";
import { setCurrentBible } from "~/state/bible/slice";
import { getVerseOfTheDay } from "~/state/bible/actions";
import { isAndroid } from "~/utils/platform";

import { BibleItem } from "./components/bible-item";
import { Container, SectionText } from "./styles";
import { messages } from "./intl";

interface Props {
  onClose: () => void;
}

export const VersionModalComponent = React.memo(({ onClose }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const netinfo = useAppNetInfo();

  const currentBible = useAppSelector(getCurrentBible);
  const languages = useAppSelector(getBibleLanguages);
  const data = useAppSelector(getBibles);

  const dispatch = useAppDispatch();

  const onAction = React.useCallback(() => {
    setIsLoading(false);
  }, []);

  const onSelect = React.useCallback(
    (id: string) => {
      if (isLoading) {
        return;
      }
      asyncLogEvent(events.SELECT_BIBLE_VERSION, { bibleId: id });
      setIsLoading(true);
      dispatch(
        setCurrentBible({ bibleId: id, onSuccess: onAction, onError: onAction })
      );
      dispatch(getVerseOfTheDay({ bibleVersion: id }));
    },
    [dispatch, isLoading, onAction]
  );

  return (
    <>
      <ModalHeader
        title={messages.title}
        onLeftPress={onClose}
        onRightPress={onClose}
        hasSeparator
      />
      <Container>
        {languages.map((lang: string) => (
          <React.Fragment key={lang}>
            <SectionText>{messages[lang]}</SectionText>
            <>
              {data
                .filter((bible) => bible.lang === lang)
                .map(({ abbreviation, abbreviationLocal, name }) => {
                  const isActive = abbreviation === currentBible;
                  return (
                    <BibleItem
                      abbreviation={abbreviation}
                      abbreviationLocal={abbreviationLocal}
                      name={name}
                      isActive={isActive}
                      onPress={onSelect}
                      isConnected={netinfo.isConnected}
                      key={abbreviation}
                    />
                  );
                })}
            </>
          </React.Fragment>
        ))}
      </Container>
    </>
  );
});

export const VersionModal = React.memo(() => {
  const navigation = useNavigation();

  const onClose = React.useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <Screen screenName="bible-version-modal" isModal={!isAndroid}>
      <VersionModalComponent onClose={onClose} />
    </Screen>
  );
});
