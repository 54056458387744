export const generateThumbnail = (videoUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.crossOrigin = "anonymous"; // Avoid CORS issues
    video.muted = true;
    video.playsInline = true;

    video.onloadedmetadata = () => {
      video.currentTime = Math.min(1, video.duration / 2);
    };

    video.onseeked = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Canvas context not supported"));
        return;
      }

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Play and pause immediately to ensure frame is rendered
      video.play().then(() => {
        video.pause();
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw frame

        canvas.toBlob((blob) => {
          if (blob) {
            const thumbnailUrl = URL.createObjectURL(blob);
            resolve(thumbnailUrl);
          } else {
            reject(new Error("Failed to generate thumbnail"));
          }
        }, "image/jpeg");
      });
    };

    video.onerror = () => reject(new Error("Error loading video"));
    video.load();
  });
};
