import { windowWidth, isWeb } from "~/utils/platform";

const MAX_WIDTH = 318;

export const getCardWidth = (isSingle?: boolean): number => {
  if (isSingle && !isWeb) {
    return windowWidth - 24;
  }
  return Math.min(MAX_WIDTH, 0.9 * windowWidth);
};
