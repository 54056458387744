import * as FileSystem from "~/utils/file-system";
import { SagaIterator } from "redux-saga";
import { format } from "date-fns";
import { call, put, takeLatest, select, all } from "redux-saga/effects";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  QueryDocumentSnapshot,
  query,
  where,
  DocumentSnapshot,
} from "firebase/firestore";
import NetInfo from "@react-native-community/netinfo";

import { database } from "<config>/firebase";
import {
  setBibleData,
  setBooksData,
  setChaptersData,
  resetChaptersData,
  setCurrentBible,
  setVerseOfTheDay,
} from "~/state/bible/slice";
import {
  BIBLE_DIRECTORY,
  FS_OPTIONS,
  SUPPORTED_BIBLE_VERSIONS,
} from "~/constants";
import {
  getBibleCollection,
  getVerseCollection,
} from "~/constants/collections";
import { setIsUserReady } from "~/state/user/actions";

import {
  getFullBible,
  removeBibleData,
  getChapters,
  getVerseOfTheDay,
} from "./actions";
import {
  getCurrentBible,
  getCurrentBook,
  getChaptersByBookId,
  getVerseOfTheDay as getVerseOfTheDaySelector,
} from "./selectors";
import {
  Bible,
  FullBook,
  Chapter,
  BibleAction,
  GetChaptersAction,
  VerseOfTheDay,
  GetVerseOfTheDayAction,
} from "./types";

import { getEnvironment } from "../user/selectors";
import { handleError } from "~/utils/logger";
import { getCombinedVerses } from "~/utils/bible";

const booksCollection = "books";

function* getFromFileSystem(bibleId: string, fileName: string): SagaIterator {
  try {
    const uri = `${BIBLE_DIRECTORY}/${bibleId}/${fileName}`;
    const file = yield call(FileSystem.getInfoAsync, uri);
    if (file.exists) {
      const result = yield call(FileSystem.readAsStringAsync, uri, FS_OPTIONS);
      return JSON.parse(result).data;
    }
  } catch (e) {
    yield call(handleError, e);
    return null;
  }
}

function* getAndStoreBibleData(
  bibleId: string,
  downloadResults: boolean
): SagaIterator {
  const env = yield select(getEnvironment);
  const bibleCollection = getBibleCollection(env);

  const dataUri = `${BIBLE_DIRECTORY}/${bibleId}/data.json`;

  // Check if we have this data already downloaded
  const downloadedData = yield call(getFromFileSystem, bibleId, "data.json");

  if (downloadedData) {
    return downloadedData;
  }

  // Get the Bible data from Firebase
  const bibleRef = doc(database, bibleCollection, bibleId);
  // @ts-ignore
  const bibleSnapshot = yield call(getDoc, bibleRef);
  if (bibleSnapshot.exists()) {
    const data = bibleSnapshot.data();

    if (data.bibleId) {
      if (downloadResults) {
        // Store Bible data
        yield call(
          FileSystem.writeAsStringAsync,
          dataUri,
          JSON.stringify({ data }),
          FS_OPTIONS
        );
      }

      return data;
    }
  }
}

function* getAndStoreBooks(
  bibleId: string,
  downloadResults: boolean
): SagaIterator {
  const env = yield select(getEnvironment);
  const bibleCollection = getBibleCollection(env);

  const booksUri = `${BIBLE_DIRECTORY}/${bibleId}/books.json`;

  // Check if we have this data already downloaded
  const downloadedBooks = yield call(getFromFileSystem, bibleId, "books.json");
  if (downloadedBooks) {
    return downloadedBooks;
  }

  // Get the books data from Firebase
  const booksRef = collection(
    database,
    bibleCollection,
    bibleId,
    booksCollection
  );
  // @ts-ignore
  const booksSnapshots = yield call(getDocs, booksRef);

  const books: FullBook[] = [];
  booksSnapshots.forEach((book: QueryDocumentSnapshot<FullBook>) => {
    if (book.exists()) {
      books.push(book.data());
    }
  });
  if (downloadResults) {
    // Store books of the Bible
    yield call(
      FileSystem.writeAsStringAsync,
      booksUri,
      JSON.stringify({ data: books }),
      FS_OPTIONS
    );
  }

  return books;
}

function* getAndStoreChapters(
  bibleId: string,
  bookId: string,
  downloadResults: boolean
): SagaIterator {
  const env = yield select(getEnvironment);
  const bibleCollection = getBibleCollection(env);

  const bibleDirectory = `${BIBLE_DIRECTORY}/${bibleId}`;
  const bookUri = `${bibleDirectory}/${bookId}.json`;

  // Check if we have this data already downloaded
  const downloadedBooks: FullBook[] = yield call(
    getFromFileSystem,
    bibleId,
    "books.json"
  );
  const downloadedChapters =
    downloadedBooks?.find((book) => book.bookId === bookId)?.chapters || [];

  if (downloadedChapters.length) {
    return {
      chapters: downloadedChapters,
      bookId,
    };
  }

  // Get chapters of the book from Firebase
  const bookRef = doc(
    database,
    bibleCollection,
    bibleId,
    booksCollection,
    bookId
  );
  // @ts-ignore
  const bookSnapshot = yield call(getDoc, bookRef);
  const chapters: Chapter[] = bookSnapshot.data().chapters;

  if (downloadResults) {
    // Store chapters of the book
    yield call(
      FileSystem.writeAsStringAsync,
      bookUri,
      JSON.stringify({ data: chapters }),
      FS_OPTIONS
    );
  }

  return {
    chapters,
    bookId,
  };
}

export function* preloadBibleData(): SagaIterator {
  try {
    const { isConnected }: Awaited<ReturnType<typeof NetInfo.fetch>> =
      yield call(NetInfo.fetch);

    if (!isConnected) return;

    const bibleName = yield select(getCurrentBible);
    const currentBook = yield select(getCurrentBook);

    // Get the data of all the available Bible versions
    const callEffects = SUPPORTED_BIBLE_VERSIONS.map((abbreviation) => {
      return call(getAndStoreBibleData, abbreviation, false);
    });
    const allBibleData = yield all(callEffects);
    const data = allBibleData.filter((item?: Bible) => !!item);

    if (data.length) {
      yield put(setBibleData(data));
    }

    // Get the books of the current Bible
    const books: FullBook[] = yield call(getAndStoreBooks, bibleName, false);
    if (books.length) {
      const booksWithoutChapters = books.map(({ chapters, ...rest }) => rest);
      const currentBookChapters =
        books.find((book) => book.bookId === currentBook)?.chapters || [];
      yield put(setBooksData(booksWithoutChapters));
      yield put(
        setChaptersData({ bookId: currentBook, chapters: currentBookChapters })
      );
      return;
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* bibleSaga() {
  yield takeLatest(setIsUserReady.type, preloadBibleData);
}

export function* handleGetChapters({
  payload: { bookId, ignoreSaved, onError },
}: GetChaptersAction): SagaIterator {
  try {
    const bibleName = yield select(getCurrentBible);
    const currentChapters = yield select(getChaptersByBookId, bookId);

    if (currentChapters.length && !ignoreSaved) {
      return;
    }

    // Get the chapters data
    const data = yield call(getAndStoreChapters, bibleName, bookId, false);
    if (data.chapters.length) {
      yield put(setChaptersData({ bookId, chapters: data.chapters }));
    }
  } catch (e) {
    yield call(handleError, e);
    yield put(resetChaptersData());
    yield call(onError);
  }
}

export function* chaptersSaga() {
  yield takeLatest(getChapters.type, handleGetChapters);
}

export function* handleGetFullBible({
  payload: { bibleId, onSuccess, onError },
}: BibleAction): SagaIterator {
  try {
    const bibleDirectory = `${BIBLE_DIRECTORY}/${bibleId}`;
    const metaUri = `${bibleDirectory}/meta.json`;

    // Create a directory for the corresponding Bible version
    yield call(FileSystem.makeDirectoryAsync, bibleDirectory, {
      intermediates: true,
    });

    // Store Bible data
    yield call(getAndStoreBibleData, bibleId, true);

    // Store books
    yield call(getAndStoreBooks, bibleId, true);

    // Store the summary of the operation
    yield call(
      FileSystem.writeAsStringAsync,
      metaUri,
      JSON.stringify({
        downloaded: true,
      }),
      FS_OPTIONS
    );
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* bibleDownloadSaga() {
  yield takeLatest(getFullBible.type, handleGetFullBible);
}

export function* handleDeleteBible({
  payload: { bibleId, onSuccess, onError },
}: BibleAction): SagaIterator {
  try {
    const bibleDirectory = `${BIBLE_DIRECTORY}/${bibleId}`;
    yield call(FileSystem.deleteAsync, bibleDirectory, { idempotent: true });
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* bibleDeleteSaga() {
  yield takeLatest(removeBibleData.type, handleDeleteBible);
}

export function* handleBibleChange({
  payload: { bibleId, onSuccess, onError },
}: BibleAction): SagaIterator {
  try {
    const currentBook: string = yield select(getCurrentBook);
    // Get the books of the current Bible
    const books = yield call(getAndStoreBooks, bibleId, false);
    if (books.length) {
      yield put(setBooksData(books));
      yield put(
        getChapters({
          bookId: currentBook,
          ignoreSaved: true,
          onError: () => null,
        })
      );
    }
    yield call(onSuccess);
  } catch (e) {
    yield call(onError);
    yield call(handleError, e);
  }
}

export function* bibleChangeSaga() {
  yield takeLatest(setCurrentBible.type, handleBibleChange);
}

export function* handleGetVerseOfTheDay({
  payload: { bibleVersion: defaultBibleVersion },
}: GetVerseOfTheDayAction): SagaIterator {
  try {
    const today = format(new Date(), "dd/MM/yyyy");
    const env = yield select(getEnvironment);
    const bibleCollection = getBibleCollection(env);
    const verseCollection = getVerseCollection();
    const currentBibleVersion = yield select(getCurrentBible);
    const currentVerse = yield select(getVerseOfTheDaySelector);
    const bibleVersion = defaultBibleVersion || currentBibleVersion;

    if (currentVerse?.date === today && !defaultBibleVersion) {
      return;
    }

    const q = query(
      collection(database, verseCollection),
      where("date", "==", today)
    );

    const verseSnapshots = yield call(
      // @ts-ignore
      getDocs,
      q
    );

    const data = verseSnapshots.docs.map((snapshot: DocumentSnapshot) =>
      snapshot.data()
    ) as VerseOfTheDay[];

    if (data.length) {
      const verse = data[0];

      const bookRef = doc(
        database,
        bibleCollection,
        bibleVersion,
        booksCollection,
        verse.bookId
      );
      // @ts-ignore
      const bookSnapshot = yield call(getDoc, bookRef);
      const chapters: Chapter[] = bookSnapshot.data().chapters;
      const chapterData = chapters.find(
        (chapter) => Number(chapter.position) === Number(verse.chapterId)
      );
      const text = getCombinedVerses(chapterData?.verses, verse.verses);

      if (text) {
        yield put(setVerseOfTheDay({ ...verse, text }));
      }
    }
  } catch (e) {
    yield call(handleError, e);
  }
}

export function* verseOfTheDaySaga() {
  yield takeLatest(getVerseOfTheDay.type, handleGetVerseOfTheDay);
}
