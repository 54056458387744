import { List } from "react-native-paper";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import styled, { css, Theme } from "styled-components/native";
import LinearGradient from "react-native-linear-gradient";
import { fontSizes, spacers } from "~/styles/theme";
import { pxToNumber } from "~/utils/theme";
import { IconSizes } from "../../icon";
import { Text1, Text2 } from "../../text";
import { isWeb } from "~/utils/platform";

type ListItemProps = Theme & {
  isFirstItem?: boolean;
  isLastItem?: boolean;
  isLoading?: boolean;
  isSelected?: boolean;
  isPlaceholder?: boolean;
  leftIcon?: string;
  textColor?: string;
  isDarkMode?: boolean;
  isGradient?: boolean;
};

export const ListItem = styled(List.Item).attrs<ListItemProps>(
  ({ leftIcon, isPlaceholder, textColor }) => ({
    titleStyle: {
      marginLeft: leftIcon ? -pxToNumber(spacers.ss6) : 0,
      fontSize: pxToNumber(fontSizes.fs2),
      fontStyle: isPlaceholder ? "italic" : "normal",
      color: textColor,
    },
  })
)<ListItemProps>`
  background-color: ${({ isSelected, theme }) =>
    isWeb && !isSelected ? theme.colors.gray50 : theme.colors.gray100};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  ${({ isFirstItem }) =>
    isFirstItem &&
    css`
      border-top-left-radius: ${spacers.ss5};
      border-top-right-radius: ${spacers.ss5};
    `}

  ${({ isLastItem }) =>
    isLastItem &&
    css`
      border-bottom-left-radius: ${spacers.ss5};
      border-bottom-right-radius: ${spacers.ss5};
    `}

  ${({ isGradient }) =>
    isGradient &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
    `}
`;

export const LeftIcon = styled(MaterialCommunityIcons).attrs({
  size: IconSizes.Small,
})<Theme>`
  align-self: center;
  margin: 0 ${spacers.ss6} 0 ${spacers.ss5};
  color: ${({ theme }) => theme.colors.black};
`;

export const ChevronRightIcon = styled(MaterialCommunityIcons).attrs(
  ({ theme }) => ({
    name: "chevron-right",
    color: theme.colors.primaryBlue,
    size: IconSizes.Large,
  })
)`
  align-self: center;
`;

export const ChevronDownIcon = styled(MaterialCommunityIcons).attrs(
  ({ theme }) => ({
    name: "menu-down",
    color: theme.colors.primaryBlue,
    size: IconSizes.Medium,
  })
)`
  align-self: center;
  margin: 0 ${spacers.ss3};
`;

export const EditLabel = styled(Text2).attrs(({ theme }) => ({
  color: theme.colors.primaryBlue,
}))`
  align-self: center;
  margin: 0 ${spacers.ss5};
`;

export const ListItemLabel = styled(Text1)`
  text-transform: uppercase;
  font-family: SFProDisplayBold;
  letter-spacing: 1px;
  margin: ${spacers.ss4} 0;
`;

export const ListContainer = styled.View`
  padding: 0 ${spacers.ss5};
  margin: ${spacers.ss8} 0 0;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
`;

export const Loader = styled.View`
  padding-right: ${spacers.ss4};
  justify-content: center;
`;

export const ListItemDivider = styled.View`
  height: 1px;
  background-color: ${({ theme }) => theme?.colors.gray300};
`;

export const StyledSwitch = styled.Switch`
  transform: scaleX(0.7) scaleY(0.7);
`;
