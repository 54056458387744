import React from "react";
import { GestureResponderEvent } from "react-native";
import { useTheme } from "styled-components/native";
import Icon from "@expo/vector-icons/MaterialCommunityIcons";
import * as VideoThumbnails from "expo-video-thumbnails";

import { Camera } from "~/components/camera";
import { useAppNetInfo } from "~/state/hooks";
import { useAlerts } from "~/state/alerts";
import { ImageProps, useCamera } from "~/utils/hooks/use-camera";
import { colors } from "~/styles/theme";
import { genericMessages } from "~/constants/intl";

import {
  AvatarContainer,
  Container,
  UploadContainer,
  UploadedContainer,
  UploadButton,
  VideoInfoContainer,
  VideoName,
  MetaText,
  ThumbnailImage,
} from "./styles";
import { messages } from "./intl";
import { generateThumbnail as generateThumbnailWeb } from "./generate-thumbnail";
import { PickerResourceType } from "~/components/camera/types";
import { isWeb } from "~/utils/platform";
interface Props {
  videoUri: string;
  onChange: (uri: string) => void;
  onThumbnailChange: (uri: string) => void;
  isLoading: boolean;
  uploadProgress: number;
}

const maxVideoDuration = 60;

export const VideoUpload = ({
  isLoading,
  videoUri,
  onChange,
  onThumbnailChange,
  uploadProgress,
}: Props) => {
  const [thumbnailUri, setThumbnailUri] = React.useState("");
  const { isConnected } = useAppNetInfo();
  const { pushAlert } = useAlerts();
  const theme = useTheme();

  const generateThumbnail = React.useCallback(
    async (localVideoUri: string) => {
      try {
        const { uri } = await VideoThumbnails.getThumbnailAsync(localVideoUri, {
          time: 15000,
        });
        setThumbnailUri(uri);
        onThumbnailChange(uri);
      } catch (e) {
        console.warn(e);
      }
    },
    [onThumbnailChange]
  );

  const handleVideo = React.useCallback(
    async ({ uri }: ImageProps) => {
      onChange(uri);
      if (isWeb) {
        const thumbnail = await generateThumbnailWeb(uri);
        setThumbnailUri(thumbnail);
      } else {
        generateThumbnail(uri);
      }
    },
    [onChange, generateThumbnail]
  );

  const { onShowOptions, isModalVisible, handleCloseModal } = useCamera({
    handleResource: handleVideo,
    type: PickerResourceType.VIDEO,
    maxVideoDuration,
  });

  const onPress = React.useCallback(
    (e: GestureResponderEvent) => {
      if (isLoading) return;
      if (!isConnected) {
        pushAlert({
          message: genericMessages.featureUnavailableOffline,
          color: colors.gray600,
        });

        return;
      }

      onShowOptions(e);
    },
    [isConnected, isLoading, onShowOptions, pushAlert]
  );

  const getFileName = (uri: string) => {
    if (!uri || uri.startsWith("data:")) return "";
    return uri.split("/").pop() || "";
  };

  return (
    <Container>
      <UploadContainer>
        {videoUri ? (
          <UploadedContainer onPress={onPress}>
            <AvatarContainer>
              <ThumbnailImage source={{ uri: thumbnailUri }} />
            </AvatarContainer>
            <VideoInfoContainer>
              <VideoName>{getFileName(videoUri)}</VideoName>
              {uploadProgress > 0 ? (
                <MetaText>
                  {{
                    ...messages.uploading,
                    values: { progress: Math.round(uploadProgress) },
                  }}
                </MetaText>
              ) : (
                <MetaText>{messages.changeVideo}</MetaText>
              )}
            </VideoInfoContainer>
          </UploadedContainer>
        ) : (
          <UploadButton onPress={onPress}>
            <Icon
              name="camera"
              size={26}
              color={theme.colors.gray500}
              weight="light"
            />
            <MetaText>{messages.uploadVideo}</MetaText>
          </UploadButton>
        )}
      </UploadContainer>

      {isModalVisible ? (
        <Camera
          handlePicture={handleVideo}
          onClose={handleCloseModal}
          mode="video"
          maxVideoDuration={maxVideoDuration}
        />
      ) : null}
    </Container>
  );
};
