import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DEFAULT_LANGUAGE } from "~/utils/translation";

import { AppTheme, FontSize, SettingsState, VideoQuality } from "./types";

export const initialState: SettingsState = {
  languagesToDisplay: [],
  videoQuality: undefined,
  fontSize: FontSize.Medium,
  appLanguage: DEFAULT_LANGUAGE,
  appTheme: AppTheme.Default,
};

export const settingsSlice = createSlice({
  name: "flamelink",
  initialState,
  reducers: {
    setLanguagesToDisplay: (state, action: PayloadAction<string[]>) => {
      state.languagesToDisplay = action.payload;
    },
    setVideoQuality: (
      state,
      action: PayloadAction<VideoQuality | undefined>
    ) => {
      state.videoQuality = action.payload;
    },
    setFontSize: (state, action: PayloadAction<FontSize | undefined>) => {
      state.fontSize = action.payload;
    },
    setAppLanguage: (state, action: PayloadAction<string | undefined>) => {
      state.appLanguage = action.payload;
    },
    setAppTheme: (state, action: PayloadAction<AppTheme>) => {
      state.appTheme = action.payload;
    },
  },
});

export const {
  setLanguagesToDisplay,
  setVideoQuality,
  setFontSize,
  setAppLanguage,
  setAppTheme,
} = settingsSlice.actions;

export default settingsSlice.reducer;
