import styled, { css } from "styled-components/native";
import { Image } from "expo-image";

import { spacers } from "~/styles/theme";
import { Text3 } from "~/components/text";

export const Container = styled.View<{ isWebFeatured: boolean }>`
  margin-horizontal: ${spacers.ss5};
  margin-bottom: ${spacers.ss8};
  flex: 1;
  ${({ isWebFeatured }) =>
    isWebFeatured &&
    css`
      max-width: 380px;
    `}
`;

export const BottomBox = styled.View`
  margin-top: ${spacers.ss5};
`;

export const Title = styled(Text3)`
  font-family: MontserratSemiBold;
  margin-bottom: ${spacers.ss2};
  font-size: 16px;
`;

export const Description = styled(Text3)`
  font-family: Inter;
  font-weight: 400;
  margin-bottom: ${spacers.ss3};
  line-height: 24px;
  letter-spacing: 0px;
`;

export const ImageBox = styled.TouchableOpacity`
  margin-top: ${spacers.ss3};
`;

export const CardImage = styled(Image)`
  border-radius: 8px;
  overflow: hidden;
`;
