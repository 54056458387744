import { createSelector } from "@reduxjs/toolkit";
import {
  getPlanResources,
  getSessionsByPlanId,
  getPlans,
  getSessionProgressCheckConditions,
  getVolumes,
} from "~/state/flamelink/selectors";
import type { BubbleResource } from "~/state/flamelink/types";
import type { RootState } from "~/state/store";
import {
  GetPlanProgressesByUserIdData,
  GetProgressByPlanData,
  MainButtonType,
  ProgressState,
  SessionProgress,
} from "./types";

const getState = (state: RootState) => state.contentProgress;

export const getContentProgress = createSelector(
  getState,
  (state) => state.progress
);

export const getProgressByPlanId: (
  state: RootState,
  planId: string
) => GetPlanProgressesByUserIdData | undefined = createSelector(
  [getState, (_, props) => props],
  (state, planId) => state.progress?.find((plan) => plan.planId === planId)
);

export const getHomeResourcesProgress: (state: RootState) => {
  data: GetPlanProgressesByUserIdData[] | undefined;
} = createSelector(
  [getState, getPlans, getVolumes],
  (state, plans, volumes) => {
    const result = state.progress?.map((plan) => {
      const sortedSessions = [...plan.sessions].sort(
        (a, b) => b.lastUpdated - a.lastUpdated
      );

      const planData = plans.find((item) => item.id === plan.planId);
      const planVolumes = planData?.volumes || [];
      const planSessionsLength = volumes
        .filter((volume) => planVolumes.includes(volume.id))
        .map((volume) => volume.sessions)
        .flat().length;

      const completedSessionsLength = sortedSessions.filter(
        ({ state: progressState }) => progressState === ProgressState.Completed
      ).length;
      const isPlanFullyCompleted =
        completedSessionsLength === planSessionsLength;

      return {
        ...plan,
        // We remove the activeSession if the plan is fully completed
        activeSession: isPlanFullyCompleted ? undefined : sortedSessions[0],
      };
    });

    const filteredProgressData = result?.filter(
      ({ activeSession }) => !!activeSession?.sessionId
    );

    const sortedProgressData = filteredProgressData?.sort((a, b) => {
      const aLastUpdated = a.activeSession?.lastUpdated || 0;
      const bLastUpdated = b.activeSession?.lastUpdated || 0;

      return bLastUpdated - aLastUpdated;
    });

    return { data: sortedProgressData };
  }
);

export const getNumberOfDaysCompletedByPlanId: (
  state: RootState,
  planId: string
) => number = createSelector(
  [getProgressByPlanId, (rootState) => rootState],
  (progress, rootState) => {
    if (!progress) return 0;

    const completedDayFilter = (day: SessionProgress["days"][0]) =>
      day.state === ProgressState.Completed;

    const countCompletedDaysPerSession = ({
      sessionId,
      days,
    }: SessionProgress) => {
      if (!days) return 0;

      const { shouldOmitFirstDayCheck, shouldOmitLastDayCheck } =
        getSessionProgressCheckConditions(rootState, sessionId);

      if (shouldOmitFirstDayCheck) {
        return days.slice(1, days.length).filter(completedDayFilter).length;
      }

      if (shouldOmitLastDayCheck) {
        return days.slice(0, days.length - 1).filter(completedDayFilter).length;
      }

      return days.filter(completedDayFilter).length;
    };

    return progress.sessions.reduce(
      (previousValue, currentValue) =>
        previousValue + countCompletedDaysPerSession(currentValue),
      0
    );
  }
);

export const getPlansProgressCount: (state: RootState) => {
  plansInProgress?: GetPlanProgressesByUserIdData[];
  plansCompleted?: GetPlanProgressesByUserIdData[];
} = createSelector([getState, (rootState) => rootState], (state, rootState) => {
  const plansByProgress = state.progress?.map(
    ({ sessions, planId, isCompleted, ...rest }) => {
      const planSessionsLength = getSessionsByPlanId(rootState, planId).length;
      const completedSessionsLength = sessions.filter(
        ({ state: sessionState }) => sessionState === ProgressState.Completed
      ).length;

      return {
        sessions,
        planId,
        completedSessionsLength,
        planSessionsLength,
        isCompleted,
        ...rest,
      };
    }
  );

  const plansInProgress = plansByProgress?.filter(
    ({ completedSessionsLength, planSessionsLength, isCompleted }) => {
      return completedSessionsLength < planSessionsLength && !isCompleted;
    }
  );

  const plansCompleted = plansByProgress?.filter(
    ({ completedSessionsLength, planSessionsLength, isCompleted }) => {
      return completedSessionsLength === planSessionsLength || isCompleted;
    }
  );

  return {
    plansInProgress,
    plansCompleted,
  };
});

export const getSessionsInProgressByPlanId: (
  state: RootState,
  planId: string
) => SessionProgress[] | undefined = createSelector(
  [getProgressByPlanId],
  (progress) => {
    if (!progress) return undefined;

    return progress.sessions.filter(
      ({ state }) => state === ProgressState.InProgress
    );
  }
);

export const getProgressById: (
  state: RootState,
  progressId: string
) => SessionProgress | undefined = createSelector(
  [getState, (_, props) => props],
  (state, progressId) => {
    return state.progress
      ?.map((item) => item.sessions)
      ?.flat()
      ?.find((session) => session.sessionProgressId === progressId);
  }
);

export const getSessionsCompletedByPlanId: (
  state: RootState,
  planId: string
) => SessionProgress[] | undefined = createSelector(
  [getProgressByPlanId],
  (progress) => {
    if (!progress) return undefined;

    return progress.sessions.filter(
      ({ state }) => state === ProgressState.Completed
    );
  }
);

export const getFurthestSessionCompletedByPlanId: (
  state: RootState,
  planId: string
) => SessionProgress | undefined = createSelector(
  [getSessionsCompletedByPlanId],
  (sessions) => {
    if (!sessions) return undefined;

    return sessions[sessions.length - 1];
  }
);

export const getFirstSessionNotStartedByPlanId: (
  state: RootState,
  planId: string
) => SessionProgress | undefined = createSelector(
  [getProgressByPlanId, getSessionsByPlanId, (rootState) => rootState],
  (progress) => {
    if (!progress) return undefined;

    return progress.sessions.find(
      ({ state }) => state === ProgressState.NotStarted
    );
  }
);

export const getFirstSessionNotStartedWithoutProgressRecordByPlanId: (
  state: RootState,
  planId: string
) => BubbleResource | undefined = createSelector(
  [getPlanResources, getFurthestSessionCompletedByPlanId],
  (planResources, furthestSessionCompleted) => {
    if (!planResources) return undefined;

    const furthestSessionCompletedIndex = planResources.findIndex(
      ({ sessionId }) => sessionId === furthestSessionCompleted?.sessionId
    );

    return planResources[furthestSessionCompletedIndex + 1];
  }
);

export const getNormalizedProgressByPlanId: (
  state: RootState,
  planId: string
) => GetProgressByPlanData | undefined = createSelector(
  [
    getProgressByPlanId,
    getSessionsInProgressByPlanId,
    getSessionsCompletedByPlanId,
    getFirstSessionNotStartedByPlanId,
    getFirstSessionNotStartedWithoutProgressRecordByPlanId,
    getNumberOfDaysCompletedByPlanId,
    getSessionsByPlanId,
  ],
  (
    progress,
    sessionsInProgress,
    sessionsCompleted,
    firstSessionNotStarted,
    firstSessionNotStartedWithoutProgressRecord,
    numberOfDaysCompleted,
    planSessions
  ) => {
    if (!progress) return undefined;

    const hasSessionsCompleted = !!sessionsCompleted?.length;
    const isPlanCompleted = sessionsCompleted?.length === planSessions.length;

    const result: GetProgressByPlanData = {
      planProgressId: progress.planProgressId,
      planProgress: progress.sessions,
      currentSessionInProgress: sessionsInProgress?.[0],
      furthestSessionInProgress:
        sessionsInProgress?.[sessionsInProgress.length - 1],
      firstSessionNotStarted,
      firstSessionNotStartedWithoutProgressRecord,
      hasSessionsCompleted,
      numberOfDaysCompleted,
      completionDate:
        progress.dateCompleted ||
        sessionsCompleted?.sort((a, b) => b.lastUpdated - a.lastUpdated)[0]
          ?.lastUpdated,
      isPlanCompleted,
    };

    return result;
  }
);

/**
 * Main button type should be
 * - Start: if there is no session in progress and no sessions completed
 * - Resume: if there is a session in progress or there are sessions completed
 * - Restart: if all sessions are completed
 */
export const getMainButtonType: (
  state: RootState,
  planId: string
) => MainButtonType = createSelector(
  [
    getSessionsInProgressByPlanId,
    getSessionsCompletedByPlanId,
    getSessionsByPlanId,
  ],
  (sessionsInProgress, sessionsCompleted, sessions) => {
    if (!sessionsInProgress?.length && !sessionsCompleted?.length) {
      return MainButtonType.Start;
    }

    const areAllSessionsCompleted =
      (sessionsCompleted?.length ?? 0) === sessions?.length;
    const areSomeSessionsInProgress = (sessionsInProgress?.length ?? 0) > 0;

    if (areSomeSessionsInProgress || !areAllSessionsCompleted) {
      return MainButtonType.Resume;
    }

    return MainButtonType.Restart;
  }
);

export const getSessionIdBySessionProgressId: (
  state: RootState,
  sessionProgressId: string
) => string | undefined = createSelector(
  [getState, (_, props) => props],
  (state, sessionProgressId) => {
    const session = state.progress
      ?.flatMap(({ sessions }) => sessions)
      .find(({ sessionProgressId: id }) => id === sessionProgressId);

    return session?.sessionId;
  }
);
