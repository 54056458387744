import styled from "styled-components/native";
import { Image as ExpoImage } from "expo-image";

import { Text1, Text2, Text4 } from "~/components/text";
import { lineHeights, spacers } from "~/styles/theme";
import { hexToRGB } from "~/utils/colors";
import { isWeb } from "~/utils/platform";

export const CardsWrapper = styled.View`
  margin-horizontal: ${spacers.ss5};
`;

export const Card = styled.View<{
  cardWidth: number;
  withMargin?: boolean;
  isLoading: boolean;
}>`
  width: ${({ cardWidth = 0 }) => cardWidth}px;
  margin-horizontal: ${({ withMargin }) =>
    withMargin ? spacers.ss5 : spacers.ss0};
  background-color: ${({ theme }) => hexToRGB(theme.colors.white, 0.5)};
  border-radius: 20px;
  overflow: hidden;
  margin-right: ${spacers.ss5};
  margin-bottom: ${spacers.ss4};
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray300};
  opacity: ${({ isLoading }) => (isLoading ? 0.7 : 1)};
`;

export const WebEmptyCard = styled.ImageBackground`
  width: 100%;
  background-color: ${({ theme }) => hexToRGB(theme.colors.white, 0.5)};
  border-radius: 20px;
  overflow: hidden;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray300};
  height: 420px;
`;

export const CardImage = styled(ExpoImage)<{
  cardWidth: number;
  height?: number;
}>`
  width: ${({ cardWidth = 0 }) => cardWidth - 18}px;
  height: ${({ height = 100 }) => height}px;
  border-radius: 12px;
  margin: ${spacers.ss4};
`;

export const CardContent = styled.View`
  padding: ${spacers.ss2} ${spacers.ss6} ${spacers.ss6};
`;

export const Headline = styled(Text4)`
  margin-bottom: ${spacers.ss2};
  font-family: MontserratMedium;
`;

export const Description = styled(Text2)`
  margin-bottom: ${spacers.ss5};
  font-family: MontserratMedium;
`;

export const ActionsContainer = styled.View`
  max-height: 144px;
  overflow: hidden;
`;

export const ActionButton = styled.Pressable<{ isActive: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.8)};
  padding: ${spacers.ss5} ${spacers.ss6};
  margin-bottom: ${({ isActive }) => (isActive ? 0 : 6)}px;
  border-radius: 8px;
  border-width: ${({ isActive }) => (!isActive ? 1 : 0)}px;
  border-color: ${({ theme }) => theme.colors.gray300};
`;

export const ButtonText = styled(Text2)``;

export const EmptyTitle = styled(Text4)`
  margin-bottom: ${spacers.ss3};
  font-family: MontserratBold;
  ${isWeb && `font-size: 28px;margin-bottom: ${spacers.ss6};`}
`;

export const EmptyDescription = styled(Text2)`
  margin-bottom: ${spacers.ss5};
  font-family: Inter;
  line-height: ${lineHeights.lh2};
`;

export const InlineButton = styled.TouchableOpacity`
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray600};
  padding-bottom: ${spacers.ss1};
  margin-horizontal: ${spacers.ss6};
  margin-bottom: ${spacers.ss7};
  align-self: flex-start;
`;

export const InlineButtonText = styled(Text1)`
  font-family: SFProDisplayMedium;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const GroupBox = styled.View`
  margin: ${spacers.ss0} ${spacers.ss7} ${spacers.ss5};
`;

export const GroupName = styled(Text1)`
  font-family: SFProDisplayMedium;
  letter-spacing: 1px;
`;

export const ActiveItemWrapper = styled.View`
  margin-bottom: 6px;
`;

export const MetaBox = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${spacers.ss5};
`;

export const MetaText = styled(Text1)`
  color: ${({ theme }) => theme.colors.gray500};
  font-family: SFProDisplayMedium;
`;

export const AvatarContainer = styled.View`
  flex-direction: row;
`;

export const AvatarBox = styled.View`
  margin-left: ${spacers.ss3};
  top: ${spacers.ss3};
`;

export const DeleteButton = styled.TouchableOpacity`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;
